import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, ProgressBar, Card, Modal, Alert } from 'react-bootstrap';
import { useOutletContext, useNavigate } from 'react-router-dom';
//import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
import PaginationComponent from '../components/pagination/PaginationComponent.js'
import SpinnerComponent from '../components/progressbar/SpinnerComponent.js'
import { useAuth0 } from "@auth0/auth0-react";
import { apiGet, apiPut } from "../services/AuthService.js";

//TBD: see if changing to match pagination component pattern fixes errors
function MappingsModal(props) {
    const { handlemapbutton, datafile } = props

    return (
        <Modal
            {...props}
            handlemapbutton
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>Mappings</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6>Specify the mappings below for this file:</h6>

                <Container>

                    {(() => {
                        if (!datafile.ownersMapped) {
                            return <Row style={{ marginTop: '40px' }} >
                                <Col xs={3} >
                                    Owner Mappings:
                                </Col>
                                <Col xs={9} >
                                    <Button
                                        type='button'
                                        size='sm'
                                        style={{ width: '200px' }}
                                        disabled={false}
                                        onClick={() => handlemapbutton('owners')}
                                    >
                                        Map Owners
                                    </Button>
                                </Col>
                            </Row>
                        }
                    })()}

                    {(() => {
                        if (!datafile.cdexCodesMapped) {
                            return <Row style={{ marginTop: '20px' }} >
                                <Col xs={3} >
                                    CDEX Code Mappings:
                                </Col>
                                <Col xs={9} >
                                    <Button
                                        type='button'
                                        size='sm'
                                        style={{ width: '200px' }}
                                        disabled={false}
                                        onClick={() => handlemapbutton('cdexCodes')}
                                    >
                                        Map CDEX Codes
                                    </Button>
                                </Col>
                            </Row>


                        }
                    })()}

                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

const CompanyReport = props => {

    //properties
    const { getAccessTokenSilently } = useAuth0();

    //stateful properties (hooks)
    const navigate = useNavigate();
    const [currentAccount, accountProfile, setAccountProfile, toggleIsShowSidebarMd, setIsShowSidebarMd] = useOutletContext();
    const [pageStatus, setPageStatus] = useState('loading');
    const [dataStatus, setDataStatus] = useState('');
    const [pageErrors, setPageErrors] = useState('');
    const [dataErrors, setDataErrors] = useState('');
    const [operators, setOperators] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [dataFiles, setDataFiles] = useState([]);
    const [selectedDataFile, setSelectedDataFile] = useState([]);
    const [years, setYears] = useState([]);
    const [months, setMonths] = useState([]);
    const [statusItems, setStatusItems] = useState(['', 'File Load Processing', 'Mapping Needed', 'Ready to Send', 'Sent']);
    const [companyTypes, setCompanyTypes] = useState([{ id: 0, companyType: 'All' }, { id: 1, companyType: 'Operater' }, { id: 4, companyType: 'Owner' }]);
    const [dataCriteria, setDataCriteria] = useState({
        skipCount: 0,
        takeCount: 50,
        companyTypeId: 0,
        companyName: ''
    });
    const [rowCount, setRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [screenHeight, setScreenHeight] = useState(100);

    const [modalShow, setModalShow] = React.useState(false);

    const [alertShow, setAlertShow] = React.useState(false);
    const [alertVariant, setAlertVariant] = React.useState('success');
    const [alertMessage, setAlertMessage] = React.useState('Statements sent successfully!');

    //initialize page
    useEffect(() => {
        (async () => {
            //console.log('Initializing page...');

            if (setIsShowSidebarMd)
                setIsShowSidebarMd(true);

            //get inner browser window height
            const height = window.innerHeight;
            setScreenHeight(height);

            let currentCriteria = {
                ...dataCriteria,
                skipCount: 0
            };

            await populateCompaniesInitial(currentCriteria)
                .then(response => {
                    if (response.data.length === 0) {
                        console.log(response.data);
                        setDataStatus('nodata')
                    }
                    else {
                        //console.log('DataFile Count: ' + response.data[0].rowCount);
                        setRowCount(response.data[0].rowCount);
                        setCompanies(response.data);
                        setDataStatus('ready')
                    }

                    setPageStatus('ready')
                })
                .catch(ex => {
                    setPageStatus('error');
                    setPageErrors(ex.message);
                })
                .finally(response => {
                    //do something
                })
        })();
    }, [window.innerHeight]);

    //initialize form
    const initializeForm = async () => {
        //TODO: might just call populateOperators() from useEffect
        //console.log('Initializing form...');

        //get dates for to/from dropdowns
        let dates = await getDates();

        //get operators for dropdown
        let operators = await getOperators();

        return { success: true, data: operators.data, dates: dates };

        //TODO: might want to clean this up with .then /.catch, but how to do with multiple api calls?
        //.then(get other data)
        //.catch(ex => throw...)
    };

    const getDates = () => {

        const date = new Date();
        //const day = date.getDate();
        const currMonth = date.getMonth() + 1;
        const currYear = date.getFullYear();

        const fromMonth = currMonth === 1 ? 12 : currMonth - 2;
        const fromYear = currMonth === 1 ? currYear - 1 : currYear;

        const years = [currYear - 3, currYear - 2, currYear - 1, currYear, currYear + 1];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        return { months: months, years: years, currMonth: currMonth, currYear: currYear, fromMonth: fromMonth, fromYear: fromYear };
    };

    //get operators
    const getOperators = async () => {
        //console.log('Getting Operators...');

        console.log('AccountId: ' + currentAccount.id);

        const apiUrl = 'accounts/' + currentAccount.id + '/managedsenders';
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json };
    };

    //handle form control changes
    const handleChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;
        console.log('Field: ' + fieldName + ', Value: ' + value);

        setDataCriteria({
            ...dataCriteria,
            [fieldName]: value
        });
    }

    //handle form submit
    const submitForm = async (e) => {
        e.preventDefault();
        setDataStatus('loading');

        setCurrentPage(1);

        const currentCriteria = {
            ...dataCriteria,
            skipCount: 0
        };

        await populateCompaniesInitial(currentCriteria)
            .then(response => {
                if (response.data.length === 0) {
                    console.log(response.data);
                    setDataStatus('nodata')
                }
                else {
                    //console.log('DataFile Count: ' + response.data[0].rowCount);
                    setRowCount(response.data[0].rowCount);
                    setCompanies(response.data);
                    setDataStatus('ready')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //handle data file status changes
    const handleStatusChange = async (e, dataFileId, currentStatusId, arrayIndex) => {
        e.preventDefault();

        const newStatusId = currentStatusId + 1;
        //alert('Status Change: ' + e.target.name + ' for ' + dataFileId + ' at index ' + arrayIndex + ' from ' + currentStatusId + ' to ' + newStatusId);

        await updateDataFileStatus(dataFileId, newStatusId)
            .then(response => {
                if (response.success) {
                    //https://stackoverflow.com/questions/29537299/how-can-i-update-state-item1-in-state-using-setstate
                    let dFiles = [...dataFiles];
                    let dFile = { ...dFiles[arrayIndex] };
                    dFile.statusId = newStatusId;
                    dFiles[arrayIndex] = dFile;
                    setDataFiles(dFiles);

                    setAlertMessage("Statements sent successfully!");
                    setAlertShow(true);
                    setAlertVariant('success');
                }
                else {
                    setAlertMessage("Send failed.");
                    setAlertShow(true);
                    setAlertVariant('danger');
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                setTimeout(() => {
                    setAlertShow(false);
                }, 5000);
            })
    };

    //update datafile status
    const updateDataFileStatus = async (dataFileId, newStatusId) => {
        console.log('Updating DataFile Status...');

        const apiUrl = 'datafiles/' + dataFileId + '/status';
        const jsonBody = JSON.stringify({ id: dataFileId, statusId: newStatusId });
        //console.log(apiUrl);
        //console.log(jsonBody);

        const response = await apiPut(apiUrl, jsonBody, getAccessTokenSilently);

        return { success: response.ok, statusId: newStatusId };
    };

    //handle page click
    const handlePageClick = async (currentPage) => {
        console.log('Current Page: ' + currentPage);

        setDataStatus('loading');

        let skipCount = (currentPage === 1 ? 0 : (currentPage - 1) * dataCriteria.takeCount);
        console.log('Skip Count: ' + skipCount);
        setDataCriteria({
            ...dataCriteria,
            skipCount: skipCount
        });

        const currentCriteria = {
            ...dataCriteria,
            skipCount: skipCount
        };

        await populateCompaniesInitial(currentCriteria)
            .then(response => {
                if (response.data.length === 0) {
                    console.log(response.data);
                    setDataStatus('nodata')
                }
                else {
                    setCompanies(response.data);
                    setDataStatus('ready')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //populate companies
    const populateCompaniesInitial = async (currentCriteria) => {
        //console.log('Getting DataFiles...');

        const apiUrl = 'a_report/companies?skipCount=' + currentCriteria.skipCount + '&takeCount=' + currentCriteria.takeCount + '&companyName=' + currentCriteria.companyName + '&companyTypeId=' + currentCriteria.companyTypeId;
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json };
    };

    //handle mappings button
    const handleMappingsButton = async (e, dataFile) => {
        e.preventDefault();

        console.log('Owners Mapped: ' + dataFile.ownersMapped);
        console.log('Cdex Codes Mapped: ' + dataFile.cdexCodesMapped);

        setDataStatus('loading');

        setSelectedDataFile(dataFile);
        setModalShow(true)

        setDataStatus('ready')
    };

    const mappingButtonClick = (mappingType) => {

        console.log('Mapping Type: ' + mappingType);

        switch (mappingType) {
            case 'owners':
                navigate('/mappings/' + selectedDataFile.id);
                break;
            case 'cdexCodes':
                navigate('/unmappedcdex/' + selectedDataFile.id);
                break;
            default:
            //do nothing
        }
    };


    ////render page loading
    ////note: spinner overlay will eventually be at the page level (I think)
    //const showPageLoading = () => {
    //    return (
    //        <div>
    //            <img src={spinnerIcon} alt="loading..." />
    //        </div>
    //    );
    //}

    ////render page loading
    //const showDataLoading = () => {
    //    return (
    //        <Row>
    //            <img src={spinnerIcon} alt="loading..." />
    //        </Row>
    //    );
    //}

    //render page loading
    //note: spinner overlay will eventually be at the page level (I think)
    const showPageLoading = () => {
        return (
            <div>
                <SpinnerComponent />
            </div>
        );
    }

    //render page loading
    const showDataLoading = () => {
        return (
            <Row>
                <SpinnerComponent />
            </Row>
        );
    }

    //render page error
    const showPageError = () => {
        return (
            <div>
                Oops, there was a page load error: {pageErrors}
            </div>
        );
    }

    //render data error
    const showDataError = () => {
        return (
            <Row>
                Oops, there was a data load error: {dataErrors}
            </Row>
        );
    }

    ////render loading
    //const showLoading_BAK = () => {
    //    return (
    //        <div>
    //            <img src={spinnerIcon} alt="loading..." />
    //        </div>
    //    );
    //}

    //render table container
    const showTableContainer = () => {
        return (
            <Card className="mb-4">
                <Card.Header style={{ backgroundColor: 'lightgray' }}>
                    {showInputCriteria()}
                </Card.Header>
                <Card.Body style={{ height: (screenHeight - 410).toString() + 'px', overflow: "auto" }}>
                    {(() => {
                        switch (dataStatus) {
                            case 'loading':
                                return showDataLoading()
                            case 'ready':
                                return showData()
                            case 'error':
                                return showDataError()
                            default:
                                return <p>No Data Loaded.</p>
                        }
                    })()}
                </Card.Body>
                <Card.Footer style={{ backgroundColor: 'lightgray' }}>
                    <Container fluid style={{ height: '40px' }} >
                        {(() => {
                            if (dataStatus == 'ready') {
                                return <Row>
                                    <Col className="d-flex justify-content-end">
                                        <PaginationComponent
                                            itemsCount={rowCount}
                                            itemsPerPage={dataCriteria.takeCount}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            pageClickHandler={handlePageClick}
                                            alwaysShown={true}
                                        />
                                    </Col>
                                </Row>
                            }
                        })()}
                    </Container>
                </Card.Footer>
            </Card>
        );
    }

    //render input criteria
    const showInputCriteria = () => {
        return (
            <Container fluid>
                <Form>
                    <Row>

                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="CompanyName">
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control name="companyName" size="sm" aria-label="Company Code" value={dataCriteria.ownerName} style={{ width: '100' }} onChange={handleChange} />
                            </Form.Group>
                        </Col>

                        <Col xs="auto">
                            <Form.Group as={Col} className="mb-3" controlId="formCompanyType">
                                <Form.Label>Company Type</Form.Label>
                                <Form.Select name="companyTypeId" size="sm" aria-label="companyType" disabled={dataStatus === 'loading'} onChange={handleChange} >
                                    {companyTypes.map((companyType, i) => (
                                        <option key={companyType.id} value={companyType.id}>{companyType.companyType}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>

                        <Col xs="auto">
                            <Form.Group as={Col} className="mb-3" controlId="buttonSubmit">
                                <Form.Label as={Row}><br /></Form.Label>
                                <Button
                                    type='submit'
                                    size="sm"
                                    disabled={pageStatus !== 'ready'}
                                    onClick={pageStatus === 'ready' ? submitForm : null}
                                >
                                    Search
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Container>
        );
    }

    //render datafiles
    const showData = () => {
        return (
            <Container fluid>
                <Row>
                    <table className="table table-striped" aria-labelledby="tabelLabel">
                        <thead>
                            <tr>
                                <th>Company Name</th>
                                <th>Additional Name</th>
                                <th>Address 1</th>
                            </tr>
                        </thead>
                        <tbody>
                            {companies.map((comapny, i) => (
                                <tr key={i}>
                                    <td>{comapny.companyName}</td>
                                    <td>{comapny.additionalName}</td>
                                    <td>{comapny.address1}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Row>
            </Container>
        );
    }

    //render component
    return (
        <div>
            <Alert variant={alertVariant} show={alertShow} onClose={() => setAlertShow(false)} dismissible>
                {alertMessage}
            </Alert>
            <h1>Company Report</h1>
            <div><br /></div>
            {(() => {
                switch (pageStatus) {
                    case 'loading':
                        return showPageLoading()
                    case 'ready':
                        return showTableContainer()
                    case 'error':
                        return showPageError()
                    default:
                        return <p>No Page Loaded.</p>
                }
            })()}

            <MappingsModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                handlemapbutton={mappingButtonClick}
                datafile={selectedDataFile}
            />
        </div>
    );
}

export default CompanyReport;
