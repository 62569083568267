import { useOutletContext, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
import PaginationComponent from '../components/pagination/PaginationComponent.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faBuilding, faGear, faContactBook, faXmark, faArrowRight, faLink } from '@fortawesome/free-solid-svg-icons'

import Logo from './../assets/brand/pumpjack2.png';
import Cables from './../assets/brand/cables.png';
const imgStyle = {
    width: '100%',
    height: 'auto',
    borderRadius: '20px'
};

const imgPublicStyle = {
    width: '100%',
    height: 'auto',
    padding: '0px',
    margin: '0px',
    marginBottom: '0px',
    objectFit: 'fill'
};

const Reports = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    //stateful properties (hooks)
    const [currentAccount, accountProfile, setAccountProfile, toggleIsShowSidebarMd, setIsShowSidebarMd] = useOutletContext();
    const navigate = useNavigate();

    //initialize page
    useEffect(() => {
        (async () => {
            //console.log('Initializing page...');

            if (setIsShowSidebarMd) {
                setIsShowSidebarMd(true);
            }

        })();
    }, []);

    //render profile overview info
    const cardNavigate = (path) => {

        if (path === '/') {
            toggleIsShowSidebarMd();
        }
        else {
            navigate(path);
        }
    }

    //render cards
    const showCards = () => {
        return <Container>
            {(() => {
                if (accountProfile.accountType === "Admin" || accountProfile.accountType === "Sender" || accountProfile.accountType === "SenderReceiver") {
                    return <>
                        <Row>
                            <p className="display-6" style={{ fontSize: '1.65rem', fontWeight: '700' }} >Reports</p>
                        </Row>
                        <Row style={{ marginBottom: '20px' }}>
                            <Col xs={4}>
                                {showReports()}
                            </Col>
                        </Row>
                    </>
                }
                else {
                    return null
                }
            })()}

        </Container>
    }

    const showReports = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }} onClick={() => cardNavigate('/CompanyReport')}> 
            <Card.Header>
                <Row>
                    <Col xs="1">
                        <FontAwesomeIcon icon={faLink} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Reports</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body style={{ backgroundColor: 'black' }} ></Card.Body>
        </Card>
    }

    return (
        <>
            <br /><br />
            <p className="display-6" style={{ fontWeight: '500' }} >Reports</p>
            <div className="body flex-grow-1 px-3">
                <Container fluid="xxl">
                    <Row style={{ height: "25px" }} ></Row>
                    <Row style={{ marginTop: '20px' }} >
                        {showCards()}
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Reports;
