import { Route, Routes } from "react-router-dom";
//import { AdminLayout } from "./layout";
import Welcome from "./pages/Welcome";
import Operators from "./pages/Operators";
import Owners from "./pages/Owners";
import { Dashboard } from "./pages/Dashboard";
import WeatherForecast from "./pages/WeatherForecast";
import { NotFound } from "./pages/NotFound";
import Import from "./pages/Import";
import DataFiles from "./pages/DataFiles";
import Statements from "./pages/Statements";
import OwnerMappings from "./pages/OwnerMappings";
import UnmappedCdexCodes from "./pages/UnmappedCdexCodes";
import CdexCodes from "./pages/CdexCodes";
import CdexUdf from "./pages/CdexUdf";
import CheckFiles from "./pages/CheckFiles";
import LoginOLD from "./pages/LoginOLD";
import RegisterAccountOLD from "./pages/RegisterAccountOLD";
import Account from "./pages/Account";
import Profile from "./pages/Profile";
import Settings from "./pages/Settings";
import Contact from "./pages/Contact";
import JIB from "./pages/JIB";
import Ten99 from "./pages/1099";
import ACH from "./pages/Ach";
import COA from "./pages/Coa";
import Companies from "./pages/Companies";
import Membership from "./pages/Membership";
import Payment from "./pages/Payment";
import DocumentConversion from "./pages/DocumentConversion";
import DocConversion from "./pages/DocConversionTemp";
import SendingOperators from "./pages/SendingOperators";
import MappedOwners from "./pages/MappedOwners";
import UploadEmails from "./pages/UploadEmails";
import Download from "./pages/Download";
import VerifyEmail from "./pages/VerifyEmail";
import Register from "./pages/Register";
import Unregister from "./pages/Unregister";
import SignIn from "./pages/SignIn";
import AuthTest from "./pages/AuthTest";
import Reports from "./pages/Reports"
import CompanyReport from "./pages/CompanyReport";
import HelloWorld from "./pages/HelloWorld"
import { useAuth0 } from "@auth0/auth0-react";

//*********WIX REDIRECT*****************//
import Home from "./pages/Home";
import AdminLayout from "./layout/AdminLayout/AdminLayout";

//*********STANDALONE*******************//
//import Home_STANDALONE from "./pages/Home_STANDALONE";
//import AdminLayout_STANDALONE from "./layout/AdminLayout/AdminLayout_STANDALONE";


import SpinnerComponent from './components/progressbar/SpinnerComponent.js'

export default function App() {

    const { isLoading } = useAuth0();

    if (isLoading) {
        return (
            <div className="page-layout">
                <SpinnerComponent />
            </div>
        );
    }

  return (
    <Routes>
     <Route path="/" element={<AdminLayout />}>
        <Route index element={<Home />} />
     {/*<Route path="/" element={<AdminLayout_STANDALONE />}>*/}
     {/*   <Route index element={<Home_STANDALONE />} />*/}
            <Route path="import" element={<Import />} />
            {/*<Route path="export" element={<Export />} />*/}
            <Route path="welcome" element={<Welcome />} />
            <Route path="datafiles" element={<DataFiles />} />
            <Route path="statements/:id" element={<Statements />} />
            <Route path="mappings/:id" element={<OwnerMappings />} />
            <Route path="unmappedcdex/:id" element={<UnmappedCdexCodes />} />
            <Route path="cdexcodes" element={<CdexCodes />} />
            <Route path="cdexudfs" element={<CdexUdf />} />
            <Route path="checkfiles" element={<CheckFiles />} />
            <Route path="login" element={<LoginOLD />} />
            <Route path="registeraccount" element={<RegisterAccountOLD />} />
            <Route path="account" element={<Account />} />
            <Route path="profile" element={<Profile />} />
            <Route path="settings" element={<Settings />} />
            <Route path="membership" element={<Membership />} />
            <Route path="payment" element={<Payment />} />
            <Route path="companies" element={<Companies />} />
            <Route path="documentconversion" element={<DocumentConversion />} />
            <Route path="docconversion" element={<DocConversion />} />
            <Route path="sendingoperators" element={<SendingOperators />} />
            <Route path="mappedowners" element={<MappedOwners />} />
            <Route path="uploademails" element={<UploadEmails />} />
            <Route path="download" element={<Download />} />
            <Route path="contact" element={<Contact />} />
            <Route path="jib" element={<JIB />} />
            <Route path="1099" element={<Ten99 />} />
            <Route path="ach" element={<ACH />} />
            <Route path="coa" element={<COA />} />
            <Route path="operated" element={<Operators />} />
            <Route path="non-operated" element={<Owners />} />
            <Route path="verifyemail" element={<VerifyEmail />} />
            <Route path="register" element={<Register />} />
            <Route path="unregister" element={<Unregister />} />
            <Route path="signin" element={<SignIn />} />
            <Route path="authtest" element={<AuthTest />} />
            <Route path="reports" element={<Reports /> } />
            <Route path="companyreport" element={<CompanyReport />} />
            <Route path="helloworld" element={<HelloWorld />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="weather" element={<WeatherForecast />} />        
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
