import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, ProgressBar, Card, Modal, Alert } from 'react-bootstrap';
import { useOutletContext, useNavigate } from 'react-router-dom';
import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
import PaginationComponent from '../components/pagination/PaginationComponent.js'
import { useAuth0 } from "@auth0/auth0-react";
import { apiGet, apiPut, apiPost } from "../services/AuthService.js";
import { BlockBlobClient } from "@azure/storage-blob";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faArrowUpLong, faArrowDownLong, faPencil, faBuilding } from '@fortawesome/free-solid-svg-icons'
import Account from './Account';
//import { current } from '@reduxjs/toolkit';

function DocumentInputModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>STEP 1: Document Information</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>                
                <Container style={{ height: '400px' }} >
                    <Row>
                        <Col>
                            <h6 style={{ marginBottom: '0px' }} >Convert any Revenue / JIB statement to CDEX or XLS.   We support .PDF, .TIF, .TIFF, .XLSX, .XLS.</h6>
                            <h6 style={{ marginTop: '10px' }} >Thanks to our advanced conversion technology the quality of the output will be as good as if the file was sent EDI from your operator.</h6>
                        </Col>
                    </Row>
                    <Form>                        
                        <Form.Group as={Row} className="mb-3" controlId="statementTypeId" style={{ marginTop: '20px' }}>
                            <Form.Label column sm={3}>Statement Type</Form.Label>
                            <Col sm={4}>
                                <Form.Select name="statementTypeId" size="sm" value={props.newItemCriteria.statementTypeId} aria-label="Statement Type" onChange={props.handleInputChange} >
                                    {props.statementTypes.map((type, i) => (
                                        <option key={type.id} value={type.id}>{type.type}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="deliveryTimeId">
                            <Form.Label column sm={3}>Delivery Time</Form.Label>
                            <Col sm={4}>
                                <Form.Select name="deliveryTimeId" size="sm" value={props.newItemCriteria.deliveryTimeId} aria-label="Statement Type" onChange={props.handleInputChange} >
                                    {props.deliveryTimes.map((time, i) => (
                                        <option key={time.id} value={time.id}>{time.time}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="deliveryEmail">
                            <Form.Label column sm={3}>Delivery Email</Form.Label>
                            <Col sm={4}>
                                <Form.Control name="deliveryEmail" size="sm" aria-label="DeliveryEmail" value={props.newItemCriteria.deliveryEmail} placeholder="john.doe@gmail.com" style={{ width: '100' }} onChange={props.handleInputChange} isInvalid={!props.isEmailValid} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid email.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="statementCategoryId">
                            <Form.Label column sm={3}>Org (optional)</Form.Label>
                            <Col sm={6}>
                                <Form.Select name="statementCategoryId" size="sm" value={props.newItemCriteria.statementCategoryId} aria-label="Org (optional)" onChange={props.handleInputChange} >
                                    {props.statementCategories.map((category, i) => (
                                        <option key={category.id} value={category.id}>{category.code} {(category.name === '' ? '' : '-')} {category.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            
                            <Col sm={2}>
                                <Button size="sm" style={{ fontSize: '.8rem' }} onClick={props.onAddCategory}>Add New Org</Button>
                            </Col>

                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="specialInstructions">
                            <Form.Label column sm={3}>Special Instructions</Form.Label>
                            <Col sm={6}>
                                <Form.Control as="textarea" rows="3" name="specialInstructions" size="sm" aria-label="SpecialInstructions" value={props.newItemCriteria.specialInstructions} style={{ resize: 'none', whiteSpace: 'pre-line' }} maxLength="250" onChange={props.handleInputChange} />
                                <p style={{marginTop: '5px', fontSize: '.85rem'}} >Max 250 Characters</p>
                            </Col>
                        </Form.Group>
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'left' }}>
                            <Button onClick={props.onHide}>Cancel</Button>
                        </Col>
                        <Col style={{ display: 'flex', justifyContent: 'right' }}>
                            <Button onClick={props.onNext} disabled={props.newItemCriteria.deliveryEmail.length < 1 || !props.isEmailValid} >Next &gt;&gt;</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
}

function DocumentUploadModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>STEP 2: Upload Document</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container style={{ height: '400px' }} >
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center', marginBottom: '-10px' }}>
                            <h5>Supported file types to convert: .PDF, .TIF, .TIFF, .XLSX, .XLS</h5>
                        </Col>
                    </Row>
                    <Form>                        
                        <Form.Group className="mb-3" controlId="buttonSelectFile">
                            <Form.Label><br /></Form.Label>
                            <Form.Control
                                type="file"
                                size="sm"
                                multiple
                                onChange={props.handlefileInputChange}
                                accept=".pdf, .tif, .tiff .xlsx,.xls"
                            />
                        </Form.Group>
                    </Form>
                    <Row>
                        <Col>
                            <div style={{ border: (props.dragging ? '4px dashed lightgray' : '2px dashed lightgray'), borderRadius: '10px', backgroundColor: (props.dragging ? 'honeydew' : 'white'), height: '300px', color: 'gray', textAlign: 'center', verticalAlign: 'middle' }}
                                onDrop={props.handleDrop}
                                onDragOver={(event) => event.preventDefault()}
                                onDragEnter={props.handleDragEnter}
                                onDragLeave={props.handleDragLeave}
                            >
                                <p style={{ fontSize: '24px' }}>Drag and drop your files here...</p>
                                <ul style={{ listStyleType: "none", height: "240px", overflow: "auto", marginLeft: '-20px', marginRight: '20px', paddingRight: '10px' }}>
                                    {props.uploadFiles.map((file, index) => (
                                        <li key={index} style={{ marginBottom: "10px" }} >
                                            <Card className="mb-4">
                                                <Card.Header style={{ backgroundColor: 'lightgray', height: '40px' }}>                                                    
                                                    <Row>
                                                        <Col xs={11} >
                                                            <p style={{ fontWeight: '700' }} >{file.name}</p>
                                                        </Col>
                                                        <Col xs={1}>
                                                            <FontAwesomeIcon icon={faXmark} style={{ cursor: 'pointer', padding: '5px', width: '20px', height: '20px' }} onClick={() => props.handleRemoveFile(index)} />
                                                        </Col>
                                                    </Row>
                                                </Card.Header>
                                                <Card.Body style={{ height: '50px' }}>
                                                     Size: {file.size} bytes - LastModified: {file.lastModifiedDate.toLocaleDateString()}
                                                </Card.Body>
                                            </Card>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'left' }}>
                            <Button onClick={props.onHide}>&lt;&lt; Back</Button>
                        </Col>
                        <Col style={{ display: 'flex', justifyContent: 'right' }}>
                            <Button onClick={props.handleBlobUpload} disabled={props.disableUploadButton || props.uploadFiles.length === 0} >Upload Files</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
}

function CategoryAddModal(props) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>Add Organization</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container style={{ height: '125px' }} >
                    <Form>
                        <Form.Group as={Row} className="mb-3" controlId="categoryCode">
                            <Form.Label column sm={3}>Org Code</Form.Label>
                            <Col sm={5}>
                                <Form.Control name="categoryCode" size="sm" aria-label="Organization Code" style={{ width: '100' }}
                                    value={props.newCategory.categoryCode} isInvalid={!props.isCategoryCodeValid}
                                    onChange={props.handleCategoryInputChange} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter an Org Code.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="categoryName">
                            <Form.Label column sm={3}>Org Name</Form.Label>
                            <Col sm={6}>
                                <Form.Control name="categoryName" size="sm" aria-label="Organization Name" style={{ width: '400' }}
                                    value={props.newCategory.categoryName} isInvalid={!props.isCategoryNameValid}
                                    onChange={props.handleCategoryInputChange} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter an Org Name.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                    </Form>                    
                    {(() => {
                        if (props.newCategory.exceptionMessage !== "") {
                            return <Row>
                                <Col style={{ display: 'flex', justifyContent: 'center', marginBottom: '-10px' }}>
                                    <p style={{ color: 'red' }} >The entered Org Code already exists.  Codes must be unique.</p>
                                </Col>
                            </Row>
                        }
                    })()}
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'left' }}>
                            <Button onClick={props.onHide}>Cancel</Button>
                        </Col>
                        <Col style={{ display: 'flex', justifyContent: 'right' }}>
                            <Button onClick={props.handleAddCategory} disabled={props.newCategory.categoryCode.length < 1 || props.newCategory.categoryName.length < 1} >Save</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
}

function CategoryChangeConfirmationModal(props) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>Confirm Org Change</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container style={{ height: '125px' }} >
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center', marginBottom: '-10px' }}>
                            <p>Do you wish to change the Org for ALL items for this Conversion (Id: {props.categoryChangeCriteria.statementId}), or only this item?</p>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }} >
                        <Col style={{ display: 'flex', justifyContent: 'center' }} >
                            <Button
                                onClick={() => props.saveItemCategoryChange(props.categoryChangeCriteria.statementId, props.categoryChangeCriteria.sequenceNumber, props.categoryChangeCriteria.statementCategoryId, false)}
                                style={{ width: '150px' }}
                            >
                                Only this Item
                            </Button>
                            <Button
                                type='button'
                                size='sm'
                                style={{ marginLeft: '20px', width: '150px' }}
                                onClick={() => props.saveItemCategoryChange(props.categoryChangeCriteria.statementId, props.categoryChangeCriteria.sequenceNumber, props.categoryChangeCriteria.statementCategoryId, true)}
                            >
                                All Items
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'left' }}>
                            <Button onClick={props.onHide}>Cancel</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
}

const DocumentConversion = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    const { getAccessTokenSilently, user } = useAuth0();

    //stateful properties (hooks)
    const navigate = useNavigate();
    const [currentAccount, accountProfile, setAccountProfile, toggleIsShowSidebarMd, setIsShowSidebarMd] = useOutletContext();
    const [pageStatus, setPageStatus] = useState('loading');
    const [dataStatus, setDataStatus] = useState('');
    const [pageErrors, setPageErrors] = useState('');
    const [dataErrors, setDataErrors] = useState('');
    const [owners, setOwners] = useState([]);
    const [operators, setOperators] = useState([]);
    const [statementCategories, setStatementCategories] = useState([]);
    const [selectedOperator, setSelectedOperator] = useState([]);
    const [checkStatements, setCheckStatements] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [years, setYears] = useState([]);
    const [months, setMonths] = useState([]);
    const [statusItems, setStatusItems] = useState([{ id: 0, status: 'All' }, { id: 1, status: 'Processing' }, { id: 6, status: 'Complete' }, { id: 2, status: 'Available' }, { id: 3, status: 'Downloaded' }, { id: 4, status: 'Exception' }, { id: 5, status: 'Rejected' }]);
    const [statementTypes, setStatementTypes] = useState([{ id: 0, type: 'All' }, { id: 1, type: 'Revenue' }, { id: 2, type: 'JIB' }, { id: 3, type: 'Plant' }]);
    const [deliveryTimes, setDeliveryTimes] = useState([{ id: 0, time: 'All' }, { id: 1, time: 'Standard (3 days)' }, { id: 2, time: 'Rush (1 day)' }]);
    const [dataCriteria, setDataCriteria] = useState({
        skipCount: 0,
        takeCount: 50,
        statementIdString: '',
        statementTypeId: 0,
        statementCategoryId: 0,
        operatorName: '',
        ownerNumber: '',
        ownerName: '',
        checkNumber: '',
        statusId: 0,
        fromMonth: 0,
        fromYear: 0,
        toMonth: 0,
        toYear: 0,
        ownerId: '',
        checkDate: ''
    });
    const [newItemCriteria, setNewItemCriteria] = useState({
        statementTypeId: 1,
        deliveryTimeId: 1,
        statementCategoryId: 0,
        deliveryEmail: '',
        specialInstructions: '',
        selectedFile: null,
        uploadFiles: []
    });
    const [uploadFiles, setUploadFiles] = useState([]);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [dragging, setDragging] = useState(false);

    const [rowCount, setRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [screenHeight, setScreenHeight] = useState(100);

    const [documentInputModalShow, setDocumentInputModalShow] = useState(false);
    const [documentUploadModalShow, setDocumentUploadModalShow] = useState(false);
    const [categoryAddModalShow, setCategoryAddModalShow] = useState(false);
    const [categoryChangeConfirmationModalShow, setCategoryChangeConfirmationModalShow] = useState(false);

    const [alertShow, setAlertShow] = useState(false);
    const [alertVariant, setAlertVariant] = useState('success');
    const [alertMessage, setAlertMessage] = useState('Success!');
    const [progressStatus, setProgressStatus] = useState(0);

    const [sortBy, setSortBy] = useState("StatusDate");
    const [sortDirection, setSortDirection] = useState("Descending");

    const [categoryEditItem, setCategoryEditItem] = useState(null);
    const [newCategory, setNewCategory] = useState({
        id: 0,
        categoryCode: '',
        categoryName: '',
        exceptionMessage : ''
    });
    const [isCategoryCodeValid, setIsCategoryCodeValid] = useState(true);
    const [isCategoryNameValid, setIsCategoryNameValid] = useState(true);

    const [categoryChangeCriteria, setCategoryChangeCriteria] = useState({
        statementId: 0,
        sequenceNumber: 0,
        statementCategoryId: 0,
        updateAllItems: false
    });

    const [isDev, setIsDev] = useState(true);

    //initialize page
    useEffect(() => {
        (async () => {
            //console.log('Initializing page...');

            if (setIsShowSidebarMd)
                setIsShowSidebarMd(true);

            //get inner browser window height
            const height = window.innerHeight;
            setScreenHeight(height);

            await initializeForm()
                .then(response => {
                    setOperators(response.operators);
                    setOwners(response.owners);
                    setYears(response.dates.years);
                    setMonths(response.dates.months);
                    setStatementCategories(response.statementCategories);

                    setDataCriteria({
                        ...dataCriteria,
                        operatorId: response.operators[0].id,
                        //ownerId: response.owners[0] === null ? 0 : response.owners[0].id,
                        fromMonth: response.dates.fromMonth,
                        fromYear: response.dates.fromYear,
                        toMonth: response.dates.currMonth,
                        toYear: response.dates.currYear
                    });

                    setDocuments(response.documents.data);
                    setRowCount(response.documents.rowCount);

                    //default user's email address
                    setNewItemCriteria({
                        statementTypeId: 1,
                        deliveryTimeId: 1,
                        statementCategoryId: 0,
                        deliveryEmail: '',   //user.email
                        specialInstructions: '',
                        selectedFile: null,
                        uploadFiles: []
                    });
                    setUploadFiles([]);
                    setIsEmailValid(true);

                    setDataStatus('ready');
                    setPageStatus('ready');
                })
                .catch(ex => {
                    setPageStatus('error');
                    setPageErrors(ex.message);
                })
                .finally(response => {
                    //do something
                })
        })();
    }, [window.innerHeight]);

    //initialize form
    const initializeForm = async () => {
        //TODO: might just call populateOwners() from useEffect
        //console.log('Initializing form...');

        //get dates for to/from dropdowns
        const dates = await getDates();

        //get operators for dropdown
        const operators = await getOperators();
        const newOperators = [{id: 0, companyName: ''}, ...operators.data];

        //get owners for dropdown
        const owners = await getOwners();

        //get statement categories for dropdown
        const statementCategories = await getStatementCategories();

        const currentCriteria = {
            ...dataCriteria,
            fromMonth: dates.fromMonth,
            fromYear: dates.fromYear,
            toMonth: dates.currMonth,
            toYear: dates.currYear,
            skipCount: 0
        };
        const documents = await getStatements(currentCriteria, sortBy, sortDirection);

        //'&checkNumber=' + currentCriteria.checkNumber + '&checkDate=' + currentCriteria.checkDate + '&ownerIdString=' + currentCriteria.ownerId + '&statusId=' + currentCriteria.statusId + '&skipCount=' + currentCriteria.skipCount + '&takeCount=' + currentCriteria.takeCount;

        return { success: true, operators: newOperators, owners: owners.data, statementCategories: statementCategories.data, dates: dates, documents: documents.data };

        //TODO: might want to clean this up with .then /.catch, but how to do with multiple api calls?
        //.then(get other data)
        //.catch(ex => throw...)
    };

    const getDates = () => {

        const date = new Date();
        //const day = date.getDate();
        const currMonth = date.getMonth() + 1;
        const currYear = date.getFullYear();

        const fromMonth = currMonth === 1 ? 12 : currMonth - 1;
        const fromYear = currMonth === 1 ? currYear - 1 : currYear;

        const years = [currYear - 3, currYear - 2, currYear - 1, currYear, currYear + 1];
        //const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        return { months: months, years: years, currMonth: currMonth, currYear: currYear, fromMonth: fromMonth, fromYear: fromYear };
    };

    //get conversion statements
    const getStatements = async (currentCriteria, sortBy, sortDirection) => {
        //console.log('Getting Statements...');

        console.log('StatementId: ' + dataCriteria.statementId);

        let apiUrl = '';
        if (accountProfile.hasDocConversion) {
            //apiUrl = 'accounts/allconversionstatements?statementIdString=' + currentCriteria.statementIdString + '&statementTypeId=' + currentCriteria.statementTypeId + '&statementCategoryId=' + currentCriteria.statementCategoryId + '&fromMonth=' + currentCriteria.fromMonth + '&fromYear=' + currentCriteria.fromYear + '&toMonth=' + currentCriteria.toMonth + '&toYear=' + currentCriteria.toYear + '&operatorName=' + currentCriteria.operatorName + '&ownerNumber=' + currentCriteria.ownerNumber + '&ownerName=' + currentCriteria.ownerName + '&checkNumber=' + currentCriteria.checkNumber + '&checkDate=' + currentCriteria.checkDate + '&ownerIdString=' + currentCriteria.ownerId + '&statusId=' + currentCriteria.statusId + '&skipCount=' + currentCriteria.skipCount + '&takeCount=' + currentCriteria.takeCount + '&sortBy=' + sortBy + '&sortDirection=' + sortDirection;
            apiUrl = 'accounts/allconversionstatements?statementIdString=' + currentCriteria.statementIdString + '&statementTypeId=' + currentCriteria.statementTypeId + '&statementCategoryId=' + currentCriteria.statementCategoryId + '&fromMonth=' + currentCriteria.fromMonth + '&fromYear=' + currentCriteria.fromYear + '&toMonth=' + currentCriteria.toMonth + '&toYear=' + currentCriteria.toYear + '&operatorName=' + currentCriteria.operatorName + '&ownerNumber=' + currentCriteria.ownerNumber + '&ownerName=' + currentCriteria.ownerName + '&checkNumber=' + currentCriteria.checkNumber + '&checkDate=' + currentCriteria.checkDate + '&ownerIdString=' + currentCriteria.ownerId + '&statusId=' + currentCriteria.statusId + '&skipCount=' + currentCriteria.skipCount + '&takeCount=' + currentCriteria.takeCount + '&sortBy=' + sortBy + '&sortDirection=' + sortDirection;
        } else {
            apiUrl = 'accounts/' + currentAccount.id + '/conversionstatements?statementIdString=' + currentCriteria.statementIdString + '&statementTypeId=' + currentCriteria.statementTypeId + '&statementCategoryId=' + currentCriteria.statementCategoryId + '&fromMonth=' + currentCriteria.fromMonth + '&fromYear=' + currentCriteria.fromYear + '&toMonth=' + currentCriteria.toMonth + '&toYear=' + currentCriteria.toYear + '&operatorName=' + currentCriteria.operatorName + '&ownerNumber=' + currentCriteria.ownerNumber + '&ownerName=' + currentCriteria.ownerName + '&checkNumber=' + currentCriteria.checkNumber + '&checkDate=' + currentCriteria.checkDate + '&ownerIdString=' + currentCriteria.ownerId + '&statusId=' + currentCriteria.statusId + '&skipCount=' + currentCriteria.skipCount + '&takeCount=' + currentCriteria.takeCount + '&sortBy=' + sortBy + '&sortDirection=' + sortDirection;
        }

        console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json }
    };

    //get conversion statement categories
    const getStatementCategories = async () => {
        //console.log('Getting Statement Categories...');

        let apiUrl = 'accounts/' + currentAccount.id + '/conversionstatementcategories';
        console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json }
    };

    const handleAddCategory = async () => {

        //set api url
        const apiUrl = 'accounts/statementCategory';
        const body = { accountId: currentAccount.id, categoryCode: newCategory.categoryCode, categoryName: newCategory.categoryName };

        //console.log(apiUrl);

        let statementAdded = false;
        let newCategoryId = 0;
        try {
            await addStatementCategory(apiUrl, body).then(response => {
                if (response.success) {
                    if (response.data.id === 0) {
                        setNewCategory({
                            ...newCategory,
                            exceptionMessage: response.data.exceptionMessage
                        });
                    } else {
                        newCategoryId = response.data.id;
                        statementAdded = true;
                    }
                } else {
                    throw 'Failed:', response.statusText + ' : ' + response;
                }
            });

            if (statementAdded === true) {
                await getStatementCategories().then(response => {
                    if (response.success) {
                        setStatementCategories(response.data);

                        if (documentInputModalShow === true) {
                            setNewItemCriteria({
                                ...newItemCriteria,
                                statementCategoryId: newCategoryId
                            });
                        } else {
                            setDataCriteria({
                                ...dataCriteria,
                                statementCategoryId: newCategoryId
                            });
                        }

                        setNewCategory({
                            id: 0,
                            categoryCode: '',
                            categoryName: '',
                            exceptionMessage: ''
                        });

                        setAlertShow(true);
                        setAlertVariant('success');
                        setAlertMessage('Success!  ' + newCategory.categoryCode + ' - ' + newCategory.categoryName + ' was added.');      
                        
                    } else {

                        setNewCategory({
                            id: 0,
                            categoryCode: '',
                            categoryName: '',
                            exceptionMessage: ''
                        });

                        throw 'Failed:', response.statusText + ' : ' + response;
                    }

                    setCategoryAddModalShow(false);
                });
            }
        }
        catch (ex) {
            console.error('Error:', ex);
            //setDataErrors(ex.message);
            //setDataStatus('error');

            setAlertShow(true);
            setAlertVariant('danger');
            setAlertMessage('Unknown error occurred. Failed to add Org.');
        }
        finally {
            setTimeout(() => {
                setAlertShow(false);
            }, 5000);
        }
    }

    //add statement category
    const addStatementCategory = async (apiUrl, body) => {
        //console.log('Getting User Profile...');

        console.log(apiUrl);
        const jsonBody = JSON.stringify(body);
        //console.log(apiUrl);
        //console.log(jsonBody);

        const response = await apiPost(apiUrl, jsonBody, true, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Data: ' + json);

        return { success: response.ok, data: json };
    }

    const handleItemCategoryChange = async (event, statementId, sequenceNumber) => {
        event.preventDefault();

        const fieldName = event.target.name;
        const statementCategoryId = event.target.value;
        console.log('Field: ' + fieldName + ', Value: ' + statementCategoryId);

        setCategoryChangeCriteria({
            statementId: statementId,
            sequenceNumber: sequenceNumber,
            statementCategoryId: statementCategoryId,
            updateAllItems: false
        })

        //get count of items with this statementid
        const statementCount = documents.filter((doc) => doc.statementId === statementId).length;

        if (statementCount === 1) {
            await saveItemCategoryChange(statementId, sequenceNumber, statementCategoryId, false);            
        } else {
            setCategoryChangeConfirmationModalShow(true);
        }
    }

    const saveItemCategoryChange = async (statementId, sequenceNumber, statementCategoryId, updateAllItems) => {        

        setDataStatus('loading');
        setCategoryChangeConfirmationModalShow(false);

        //set api url
        const apiUrl = 'accounts/statementItemCategory';
        const body = { statementId: statementId, sequenceNumber: sequenceNumber, statementCategoryId: statementCategoryId, updateAllItems: updateAllItems };

        //console.log(apiUrl);

        let categoryUpdated = false;
        try {
            await updateStatementItemCategory(apiUrl, body).then(response => {
                if (response.success) {

                    setCategoryEditItem(null);
                    categoryUpdated = true;

                } else {
                    throw 'Failed:', response.statusText + ' : ' + response;
                }
            });

            if (categoryUpdated === true) {
                await getStatements(dataCriteria, sortBy, sortDirection).then(response => {
                    if (response.success) {
                        setDocuments(response.data.data);
                        setRowCount(response.data.rowCount);
                    }
                });
            }

            setDataStatus('ready');
        }
        catch (ex) {
            console.error('Error:', ex);
            setDataErrors(ex.message);
            setDataStatus('error');

            setAlertShow(true);
            setAlertVariant('danger');
            setAlertMessage('Unknown error occurred. Failed to update Org.');
        }
        finally {
            setTimeout(() => {
                setAlertShow(false);
            }, 5000);
        }
    }

    //add statement category
    const updateStatementItemCategory = async (apiUrl, body) => {
        //console.log('Getting User Profile...');

        console.log(apiUrl);
        const jsonBody = JSON.stringify(body);
        //console.log(apiUrl);
        //console.log(jsonBody);

        const response = await apiPut(apiUrl, jsonBody, getAccessTokenSilently);
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok);

        return { success: response.ok };
    }

    //get operators (operators this account can receive check statements from - ie. not operators "managed by this account")
    const getOperators = async () => {
        //console.log('Getting Operators...');

        console.log('AccountId: ' + currentAccount.id);

        let apiUrl = 'accounts/' + currentAccount.id + '/checksenders';
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json }
    };

    //get owners (owners managed by this account)
    const getOwners = async () => {
        //console.log('Getting Owners...');

        console.log('AccountId: ' + currentAccount.id);

        const apiUrl = 'accounts/' + currentAccount.id + '/managedreceivers';
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json }
    };

    //handle search form control changes
    const handleChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;
        console.log('Field: ' + fieldName + ', Value: ' + value);

        setDataCriteria({
            ...dataCriteria,
            [fieldName]: value
        });
    }

    //handle input form control changes
    const handleInputChange = (event) => {
        //event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;

        console.log('Field: ' + fieldName + ', Value: ' + value);

        setNewItemCriteria({
            ...newItemCriteria,
            [fieldName]: value
        });

        if (fieldName === 'deliveryEmail') {
            //***************Begin - Email************//
            var validEmail = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
            const emailMatchFound = value.search(validEmail);
            const isEmailValid = emailMatchFound > -1;
            setIsEmailValid(isEmailValid);
            //***************End - Email**************//
        }
    }

    //handle category form control changes
    const handleCategoryInputChange = (event) => {
        //event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;

        console.log('Field: ' + fieldName + ', Value: ' + value);

        setNewCategory({
            ...newCategory,
            [fieldName]: value,
            exceptionMessage: ''
        });

        if (fieldName === 'categoryCode') {
            setIsCategoryCodeValid(value.length < 1 ? false : true);
        }

        if (fieldName === 'categoryName') {
            setIsCategoryNameValid(value.length < 1 ? false : true);
        }
    }

    //handle sort link changes
    const handleSortChange = async (linkSortBy) => {
        //event.preventDefault();

        console.log('Sort By: ' + linkSortBy);

        let newSortBy = linkSortBy;
        let newSortDirection = 'Ascending';
        if (sortBy === newSortBy) {
            newSortDirection = sortDirection === 'Ascending' ? 'Descending' : 'Ascending';
            setSortDirection(newSortDirection);
        } else {
            setSortBy(newSortBy);
            setSortDirection(newSortDirection)
        }

        setDataStatus('loading');

        setCurrentPage(1);

        const currentCriteria = {
            ...dataCriteria,
            skipCount: 0
        };

        await getStatements(currentCriteria, newSortBy, newSortDirection)
            .then(response => {
                if (response.data.data.length === 0) {
                    //console.log(response.data);
                    setDataStatus('nodata')
                }
                else {
                    //console.log('Check Count: ' + response.data[0].rowCount);
                    setDocuments(response.data.data);
                    setRowCount(response.data.rowCount);

                    setDataStatus('ready')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    }

    //const handleKeypress = async (event) => {
    //    //event.preventDefault();

    //    //newline if enter key was pressed
    //    if (event.key === 'Enter') {            
    //        event.preventDefault();

    //        //this doesn't work
    //        const fieldName = event.target.name;
    //        const value = event.target.value + '\n';

    //        console.log('Field: ' + fieldName + ', Value: ' + value);

    //        setNewItemCriteria({
    //            ...newItemCriteria,
    //            [fieldName]: value
    //        });
    //    }
    //};
    
    //handle file input control changes
    const handlefileInputChange = (event) => {
        //event.preventDefault();

        const fieldName = event.target.files[0];
        const value = event.target.value;
        console.log('Field: ' + fieldName + ', Value: ' + value);

        setNewItemCriteria({
            ...newItemCriteria,
            selectedFile: event.target.files[0]
        });
    }

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setDragging(false);
    };

    //handle drag and drop event
    const handleDrop = (e) => {
        e.preventDefault();

        var newFiles = [];
        var files = e.target.files || (e.dataTransfer && e.dataTransfer.files);

        const yo = uploadFiles;
        let excludeFile = false;
        for (var i = 0; i < files.length; i++) {

            //make sure file isn't already in list
            excludeFile = false;
            for (var j = 0; j < uploadFiles.length; j++) {
                if (files[i].name === uploadFiles[j].name) {
                    excludeFile = true;
                    break;
                }
            }

            if (!files[i].name.toLowerCase().endsWith('.pdf')
                && !files[i].name.toLowerCase().endsWith('.tif')
                && !files[i].name.toLowerCase().endsWith('.tiff')
                && !files[i].name.toLowerCase().endsWith('.xlsx')
                && !files[i].name.toLowerCase().endsWith('.xls')) {
                excludeFile = true;
            }

            if (!excludeFile) {
                newFiles.push(files[i]);
            }
        }
        setUploadFiles((prevFiles) => [...prevFiles, ...newFiles]);

        e.target.value = null;
        setDragging(false);
    }

    const handleRemoveFile = (index) => {
        setUploadFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    //handle file upload
    const handleFileUpload = () => {

        const date = new Date();
        const currMonth = date.getMonth() + 1;
        const currDay = date.getDate();
        const currYear = date.getFullYear();
        const stringCurrDate = currMonth + '/' + currDay + '/' + currYear;

        //const newDocument = { id: 4, statementType: (newItemCriteria.statementTypeId == 1 ? 'Revenue' : (newItemCriteria.statementTypeId == 2 ? 'JIB' : 'Plant')), deliveryTime: (newItemCriteria.deliveryTimeId == 1 ? 'Standard (3 days)' : 'Rush (1 day)'), deliveryEmail: newItemCriteria.deliveryEmail, status: 'Pending', receivedDate: stringCurrDate, statusDate: stringCurrDate };
        //const newDocument = { statementId: 1000, statementType: (newItemCriteria.statementTypeId == 1 ? 'Revenue' : (newItemCriteria.statementTypeId == 2 ? 'JIB' : 'Plant')), operatorName: '', ownerName: '', checkNumber: '', checkDate: '', checkAmount: '', deliveryTime: (newItemCriteria.deliveryTimeId == 1 ? 'Standard (3 days)' : 'Rush (1 day)'), deliveryEmail: newItemCriteria.deliveryEmail, status: 'Pending', receivedDate: stringCurrDate, statusDate: stringCurrDate };
        const newDocument = { statementId: 1000, statementType: (newItemCriteria.statementTypeId == 1 ? 'Revenue' : (newItemCriteria.statementTypeId == 2 ? 'JIB' : 'Plant')), operatorName: 'SOUTHWOODS OPERATING LLC', ownerName: 'STEAMBOAT ENERGY LLC', checkNumber: '8000855', checkDate: '11/25/2023', checkAmount: '$122.76', deliveryTime: (newItemCriteria.deliveryTimeId == 1 ? 'Standard (3 days)' : 'Rush (1 day)'), deliveryEmail: newItemCriteria.deliveryEmail, specialInstructions: '', status: 'Pending', receivedDate: stringCurrDate, statusDate: stringCurrDate };
        documents.push(newDocument);
        setDocuments(documents);

        setDocumentInputModalShow(false);
        setDocumentUploadModalShow(false);

        setAlertVariant('success');
        setAlertMessage('Success!  An email was sent to ' + newItemCriteria.deliveryEmail + ' confirming document receipt. Please check email for further details.');
        setAlertShow(true);

        setNewItemCriteria({
            statementTypeId: 1,
            deliveryTimeId: 1,
            statementCategoryId: 0,
            deliveryEmail: '',    //user.email,
            specialInstructions: '',
            selectedFile: null,
            uploadFiles: []
        });
        setUploadFiles([]);
        setIsEmailValid(true);

        setTimeout(() => {
            setAlertShow(false);
        }, 8000);
    }

    //handle form submit
    const submitForm = async (e) => {
        e.preventDefault();

        //just return for now
        //return;

        setDataStatus('loading');

        setCurrentPage(1);

        const currentCriteria = {
            ...dataCriteria,
            skipCount: 0
        };

        await getStatements(currentCriteria, sortBy, sortDirection)
            .then(response => {
                if (response.data.data.length === 0) {
                    //console.log(response.data);
                    setDataStatus('nodata')
                }
                else {
                    //console.log('Check Count: ' + response.data[0].rowCount);
                    setDocuments(response.data.data);
                    setRowCount(response.data.rowCount);

                    setDataStatus('ready')
                }                
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //********************************************Begin - Document Upload********************************************//

    const handleBlobUpload = async () => {
        setDataStatus('uploading')

        setDocumentInputModalShow(false);
        setDocumentUploadModalShow(false);

        try {
            //reset progress indicator
            setProgressStatus(0);

            const inputFileNameArray = uploadFiles.map((uploadFile, index) => {
                return { inputFileName: uploadFile.name, blobSAS: '' };
            });

            //const initializeBlobDTO = { accountId: currentAccount.id, statementTypeId: newItemCriteria.statementTypeId, DeliveryTimeId: newItemCriteria.deliveryTimeId, DeliveryEmail: newItemCriteria.deliveryEmail, SpecialInstructions: newItemCriteria.specialInstructions, blobFiles: [{ inputFileName: 'File1', blobSAS: '' }, { inputFileName: 'File2', blobSAS: '' }, { inputFileName: 'File3', blobSAS: '' }] };
            const initializeBlobDTO = { accountId: currentAccount.id, statementTypeId: newItemCriteria.statementTypeId, DeliveryTimeId: newItemCriteria.deliveryTimeId, StatementCategoryId: newItemCriteria.statementCategoryId, DeliveryEmail: newItemCriteria.deliveryEmail, SpecialInstructions: newItemCriteria.specialInstructions, blobFiles: inputFileNameArray };

            //create blob and get sas uri
            let blobSASUri = null;
            let blobFileName = null;
            let blobFiles = null;
            await initializeBlobMultiple(initializeBlobDTO)
                .then(response => {
                    if (response.success) {
                        blobFiles = response.data.blobFiles;
                        //continue to uploadBlob below...
                    }
                    else {
                        throw 'Failed:', response.statusText + ' : ' + response;
                    }
                });

            for (var i = 0; i < blobFiles.length; i++) {
                blobSASUri = blobFiles[i].blobSAS;
                blobFileName = blobFiles[i].inputFileName;
                const blobFile = uploadFiles[i];

                //uploadBlob                
                await uploadBlobMultiple(blobSASUri, blobFile)
                    .then(response => {
                        if (response.success) {
                            //continue to createImportProcess below...
                        }
                        else {
                            throw 'Failed:', response.statusText + ' : ' + response;
                        }
                    });

                //createImportProcess
                await createConversionProcess(blobFileName)
                    .then(response => {
                        if (response.success) {
                            //console.log('Success:', response.success);
                            
                        }
                        else {
                            throw 'Failed:', response.statusText + ' : ' + response;
                        }
                    });                
            }

            //setDataStatus('ready');
            setAlertMessage('Success!  An email was sent to ' + newItemCriteria.deliveryEmail + ' confirming document receipt. Please check email for further details.');
            setAlertShow(true);
            setAlertVariant('success');

            //setDataStatus('loading');
            await getStatements(dataCriteria, sortBy, sortDirection)
                .then(response => {
                    if (response.data.data.length === 0) {
                        //console.log(response.data);
                        setDataStatus('nodata')
                    }
                    else {
                        //console.log('Check Count: ' + response.data[0].rowCount);
                        setDocuments(response.data.data);
                        setRowCount(response.data.rowCount);

                        setDataStatus('ready')
                    }
                });
        }
        catch (ex) {
            console.error('Error:', ex);
            setDataErrors(ex.message);
            setDataStatus('error');

            setAlertShow(true);
            setAlertVariant('danger');
            setAlertMessage('Upload failed.');
        }
        finally {
            //reset file selection and criteria
            setNewItemCriteria({
                statementTypeId: 1,
                deliveryTimeId: 1,
                statementCategoryId: 0,
                deliveryEmail: '',    //user.email,
                specialInstructions: '',
                selectedFile: null,
                uploadFiles: []
            });
            setUploadFiles([]);
            setIsEmailValid(true);
            //setIsFilePicked(false);

            //set the alert to close after 10 seconds
            setTimeout(() => {
                setAlertShow(false);
            }, 10000);
        }

        return { success: true };
    };

    //const handleBlobUpload_SINGLEBAK = async () => {
    //    setDataStatus('uploading')

    //    setDocumentInputModalShow(false);
    //    setDocumentUploadModalShow(false);

    //    try {
    //        //reset progress indicator
    //        setProgressStatus(0);

    //        //create blob and get sas uri
    //        let blobSASUri = null;
    //        let blobFileName = null;
    //        await initializeBlob()
    //            .then(response => {
    //                if (response.success) {
    //                    blobSASUri = response.data.blobSASUriString;
    //                    blobFileName = response.data.blobFileName;
    //                    //continue to uploadBlob below...
    //                }
    //                else {
    //                    throw 'Failed:', response.statusText + ' : ' + response;
    //                }
    //            });

    //        //uploadBlob
    //        await uploadBlob(blobSASUri)
    //            .then(response => {
    //                if (response.success) {
    //                    //continue to createImportProcess below...
    //                }
    //                else {
    //                    throw 'Failed:', response.statusText + ' : ' + response;
    //                }
    //            });

    //        //createImportProcess
    //        await createConversionProcess(blobFileName)
    //            .then(response => {
    //                if (response.success) {
    //                    console.log('Success:', response.success);
    //                    setDataStatus('ready');

    //                    setAlertMessage('Success!  An email was sent to ' + newItemCriteria.deliveryEmail + ' confirming document receipt. Please check email for further details.');
    //                    setAlertShow(true);
    //                    setAlertVariant('success');
    //                }
    //                else {
    //                    throw 'Failed:', response.statusText + ' : ' + response;
    //                }
    //            });

    //        //TODO: need review and add this
    //        ////refresh document list
    //        //setDataStatus('loading');
    //        //await getStatements()
    //        //    .then(response => {
    //        //        if (response.success) {
    //        //            console.log('Success:', response.success);
    //        //            setDataStatus('ready');

    //        //            setDocuments(response.documents.data);
    //        //            setRowCount(response.documents.data.length);
    //        //        }
    //        //        else {
    //        //            throw 'Failed:', response.statusText + ' : ' + response;
    //        //        }
    //        //    });
    //    }
    //    catch (ex) {
    //        console.error('Error:', ex);
    //        setDataErrors(ex.message);
    //        setDataStatus('error');

    //        setAlertShow(true);
    //        setAlertVariant('danger');
    //        setAlertMessage('Upload failed.');
    //    }
    //    finally {
    //        //reset file selection and criteria
    //        setNewItemCriteria({
    //            statementTypeId: 1,
    //            deliveryTimeId: 1,
    //            deliveryEmail: '',    //user.email,
    //            specialInstructions: '',
    //            selectedFile: null,
    //            uploadFiles: []
    //        });
    //        //setIsFilePicked(false);

    //        //set the alert to close after 10 seconds
    //        setTimeout(() => {
    //            setAlertShow(false);
    //        }, 10000);
    //    }

    //    return { success: true };
    //};

    ////initialize the blob and get the blob sas uri
    //const initializeBlob_SINGLEBACK = async () => {
    //    //console.log('Getting SAS Uri...');

    //    const apiUrl = 'a_import/createconversionblob?accountId=' + currentAccount.id + '&inputFileName=' + newItemCriteria.selectedFile.name + '&statementTypeId=' + newItemCriteria.statementTypeId + '&deliveryTimeId=' + newItemCriteria.deliveryTimeId + '&deliveryEmail=' + newItemCriteria.deliveryEmail + '&specialInstructions=' + newItemCriteria.specialInstructions;
    //    //console.log(apiUrl);

    //    const response = await apiGet(apiUrl, getAccessTokenSilently);
    //    const resultString = await response.text();
    //    const json = JSON.parse(resultString);
    //    console.log('Response - Status: ' + response.status + ', Success: ' + response.ok, ', Json: ' + JSON.stringify(json));

    //    return { success: response.ok, data: json };
    //}

    //initialize the blobs and get the blob sas uri's
    const initializeBlobMultiple = async (initializeBlobDTO) => {

        const apiUrl = 'a_import/createconversionblobmultiple';
        const jsonBody = JSON.stringify(initializeBlobDTO);
        //console.log(apiUrl);
        //console.log(jsonBody);

        const response = await apiPut(apiUrl, jsonBody, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json };
    };

    ////upload blob using sas
    //const uploadBlob_SINGLEBACK = async (sasTokenUrl) => {
    //    console.log('Uploading blob...');

    //    const blockBlobClient = new BlockBlobClient(sasTokenUrl);
    //    setProgressStatus(0);
    //    const response = await blockBlobClient.uploadBrowserData(newItemCriteria.selectedFile, {
    //        blockSize: 4 * 1024 * 1024,
    //        concurrency: 20,
    //        onProgress: ev => setProgressStatus(Math.floor((ev.loadedBytes / newItemCriteria.selectedFile.size) * 100))
    //    })
    //        .then((result) => {
    //            return { success: true };
    //        })
    //        .catch((ex) => {
    //            return { success: false, statusText: ex.message };
    //        });

    //    return response;
    //};

    //upload blob using sas
    const uploadBlobMultiple = async (sasTokenUrl, selectedFile) => {
        console.log('Uploading blob...');

        const blockBlobClient = new BlockBlobClient(sasTokenUrl);
        setProgressStatus(0);
        const response = await blockBlobClient.uploadBrowserData(selectedFile, {
            blockSize: 4 * 1024 * 1024,
            concurrency: 20,
            onProgress: ev => setProgressStatus(Math.floor((ev.loadedBytes / selectedFile.size) * 100))
        })
            .then((result) => {
                return { success: true };
            })
            .catch((ex) => {
                return { success: false, statusText: ex.message };
            });

        return response;
    };

    //create conversion process
    const createConversionProcess = async (blobFileName) => {
        //console.log('Creating import process...');

        const apiUrl = 'a_import/createconversionprocess?accountId=' + currentAccount.id + '&inputFileName=' + encodeURIComponent(blobFileName) + '&blobFileName=' + encodeURIComponent(blobFileName) + '&statementTypeId=' + newItemCriteria.statementTypeId + '&deliveryTimeId=' + newItemCriteria.deliveryTimeId + '&statementCategoryId=' + parseInt(newItemCriteria.statementCategoryId) + '&deliveryEmail=' + newItemCriteria.deliveryEmail + '&specialInstructions=' + encodeURIComponent(newItemCriteria.specialInstructions);
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok);

        return { success: response.ok };
    }

    //render blob uploading
    const showBlobUploading = () => {
        return (
            <>
                <Row>
                    <p><b>{(progressStatus == 0 ? 'Preparing to upload...' : (progressStatus == 100 ? 'Completing upload...' : ''))}</b></p>
                    <ProgressBar animated now={progressStatus} label={`${progressStatus}%`} />
                </Row>
                <Row>
                    <img src={spinnerIcon} alt="loading..." />
                </Row>
            </>
        );
    }

    //*********************************************End - Document Upload*********************************************//

    //*********************************************Begin - Document Download*****************************************//

    const handleBlobDownload = async (statementId, sequenceId, index) => {
        setDataStatus('loading')

        try {
            //reset progress indicator
            setProgressStatus(0);

            //create blob and get sas uri
            let statementDownloadUri = null;
            let fileName = null;
            await getStatementDownloadUri(statementId, sequenceId)
                .then(response => {
                    if (response.success) {
                        statementDownloadUri = response.data.statementDownloadUri;
                        fileName = response.data.statementBlobName;
                        //console.log(statementDownloadUri);

                        //https://stackoverflow.com/questions/29537299/how-can-i-update-state-item1-in-state-using-setstate
                        let documentList = [...documents];
                        let currentDocument = { ...documentList[index] };
                        currentDocument.statusId = 3;   //downloaded
                        currentDocument.statusString = 'Downloaded';
                        documentList[index] = currentDocument;
                        setDocuments(documentList);

                        //continue to downloadBlob below...
                    }
                    else {
                        throw 'Failed:', response.statusText + ' : ' + response;
                    }
                });

            //download blob
            //console.log('Download Filename: ' + fileName);
            await downloadBlob(statementDownloadUri, fileName)
                .then(response => {
                    if (response.success) {
                        console.log('Success:', response.success);
                        setDataStatus('ready');

                        setAlertMessage('Document was successfully downloaded!');
                        setAlertShow(true);
                        setAlertVariant('success');
                    }
                    else {
                        console.log('Failed:', response.statusText + ' : ' + response);
                        setDataStatus('ready');
                    }
                });
        }
        catch (ex) {
            console.error('Error:', ex);
            setDataErrors(ex.message);
            setDataStatus('error');

            setAlertShow(true);
            setAlertVariant('danger');
            setAlertMessage('Download failed.');
        }
        finally {
            //set the alert to close after 10 seconds
            setTimeout(() => {
                setAlertShow(false);
            }, 10000);
        }

        return { success: true };
    };

    //get the download uri
    const getStatementDownloadUri = async (statementId, sequenceId) => {
        //console.log('Getting download uri...');

        let updateDownloadStatus = true;

        if (accountProfile.hasDocConversion) {
            updateDownloadStatus = false;
        }

        let apiUrl = '';
        if (sequenceId === 0) {
            apiUrl = 'a_export/getstatementrequestdownloaduri?accountId=' + currentAccount.id + '&statementId=' + statementId;
        } else {
            apiUrl = 'a_export/getstatementdownloaduri?accountId=' + currentAccount.id + '&statementId=' + statementId + '&sequenceId=' + sequenceId + '&updateDownloadStatus=' + updateDownloadStatus;
        }
        //console.log(apiUrl);

        
        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const resultString = await response.text();
        const json = JSON.parse(resultString);
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok, ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json };
    }

    //download blob using sas
    const downloadBlob = async (sasTokenUrl, fileName) => {
        console.log('Downloading blob...');

        const blockBlobClient = new BlockBlobClient(sasTokenUrl);

        //note: streaming only works for upload in browser; will need to change to stream from Web Api later to get progress
        //const downloadResponse = await blockBlobClient.download({
        //    blockSize: 4 * 1024 * 1024,
        //    concurrency: 20,
        //    onProgress: ev => console.log('Download Progress: ' + ev)
        //});
        const downloadResponse = await blockBlobClient.download();

        const response = await downloadResponse.blobBody
            .then(blobBody => {
                // Creating new object of file
                const fileURL = window.URL.createObjectURL(blobBody);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = fileName;
                alink.click();

                return { success: true };
            })
            .catch((ex) => {
                return { success: false, statusText: ex.message };
            });

        setDataStatus('ready');
        return response;
    };

    //*********************************************End - Document Download*******************************************//

    //handle page click
    const handlePageClick = async (currentPage) => {
        console.log('Current Page: ' + currentPage);

        setDataStatus('loading');

        let skipCount = (currentPage === 1 ? 0 : (currentPage - 1) * dataCriteria.takeCount);
        console.log('Skip Count: ' + skipCount);
        setDataCriteria({
            ...dataCriteria,
            skipCount: skipCount
        });

        const currentCriteria = {
            ...dataCriteria,
            skipCount: skipCount
        };

        await getStatements(currentCriteria, sortBy, sortDirection)
            .then(response => {
                if (response.data.data.length === 0) {
                    //console.log(response.data);
                    setDataStatus('nodata')
                }
                else {
                    //console.log('Check Count: ' + response.data[0].rowCount);
                    setDocuments(response.data.data);
                    setRowCount(response.data.rowCount);

                    setDataStatus('ready')
                }   
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //populate check statements
    const populateCheckStatements = async (skipCount) => {
        //console.log('Getting CheckStatements...');

        const apiUrl = 'accounts/' + currentAccount.id + '/checkstatements?operatorId=' + dataCriteria.operatorId + '&skipCount=' + skipCount + '&takeCount=' + dataCriteria.takeCount + '&companyCode=' + dataCriteria.companyCode + '&fromMonth=' + dataCriteria.fromMonth + '&fromYear=' + dataCriteria.fromYear + '&toMonth=' + dataCriteria.toMonth + '&toYear=' + dataCriteria.toYear + '&statusId=' + dataCriteria.statusId;
        //console.log(apiUrl);
        
        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json }
    };

    const downloadCdex = async (exportType, checkId, index) => {

        const checkStatement = { ...checkStatements[index] };
        const senderId = checkStatement.operatorId;
        const cdexSenderCode = checkStatement.cdexSenderCode;
        console.log('CheckId: ' + checkId + ', SenderId: ' + senderId + ', Cdex Sender Code: ' + cdexSenderCode);
        if ((cdexSenderCode ?? '') == '') {
            setSelectedOperator(checkStatement);
            setDocumentInputModalShow(true);
        }
        else {
            //alert('downloading...');
            await downloadPdf(exportType, checkId, index);
        }       
    }

    //update cdexsendercode
    const updateCdexSenderCode = async (operatorId, cdexSenderCode) => {
        console.log('Saving cdexsendercode...');
        //setDataStatus('loading');

        console.log('OperatorId: ' + operatorId + ', CdexSenderCode: ' + cdexSenderCode);

        var selectedCompany = { id: operatorId, cdexSenderCode: cdexSenderCode };

        await saveCdexSenderCode(selectedCompany)
            .then(response => {
                if (response.success) {
                    setCheckStatements(checkStatements.map((checkStatement, index) => {
                        if (checkStatement.operatorId == selectedCompany.id) {
                            return { ...checkStatement, cdexSenderCode: cdexSenderCode };
                        }
                        else {
                            return checkStatement;
                        }
                    }));
                    console.log('ready');
                    //setDataStatus('ready');
                    setDocumentInputModalShow(false);
                }
                else {
                    //TODO: figure out what to do here
                    console.log('nodata');
                    //setDataStatus('nodata')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //save cdexsendercode
    const saveCdexSenderCode = async (selectedCompany) => {
        console.log('Saving cdexsendercode...');

        const apiUrl = 'accounts/' + currentAccount.id + '/updatesendingoperator/' + selectedCompany.id;
        const jsonBody = JSON.stringify(selectedCompany);
        //console.log(apiUrl);
        //console.log(jsonBody);

        const response = await apiPut(apiUrl, jsonBody, getAccessTokenSilently);

        return { success: response.ok };
    };

    const downloadPdf = async (exportType, checkId, index) => {
        try {
            setDataStatus('loading');

            //let apiUrl = baseUrl + 'a_export/';
            let apiUrl = 'a_export/';
            let fileName = 'RevenueStatement.';
            switch (exportType) {
                case 'Pdf':
                    apiUrl = apiUrl + 'pdf';
                    fileName = fileName + 'pdf';
                    break;
                case 'Excel':
                    apiUrl = apiUrl + 'excel';
                    fileName = fileName + 'xlsx';
                    break;
                case 'Cdex':
                    apiUrl = apiUrl + 'cdex';
                    fileName = fileName + 'dat';                    
                    break;
                default:
                    break;
            }

            apiUrl = apiUrl + '?checkid=' + checkId + '&flaggenerated=true';
            //console.log(apiUrl);

            await getDocument(checkId, apiUrl).then(response => {
                response.data.blob().then(blob => {
                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(blob);
                    // Setting various property values
                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = fileName;
                    alink.click();

                    //https://stackoverflow.com/questions/29537299/how-can-i-update-state-item1-in-state-using-setstate
                    let checks = [...checkStatements];
                    let check = { ...checkStatements[index] };
                    check.ownerStatus = true;
                    checks[index] = check;
                    setCheckStatements(checks);

                    setDataStatus('ready');
                })
            });
        } catch (ex) {
            console.log(ex.message);
            setDataStatus('error');
            setDataErrors(ex.message);
        }        
    };

    //getDocument
    const getDocument = async (checkId, apiUrl) => {
        //console.log('Getting User Profile...');

        console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Data: ' + response);

        return { success: response.ok, data: response };
    }

    //render page loading
    //note: spinner overlay will eventually be at the page level (I think)
    const showPageLoading = () => {
        return (
            <div>
                <img src={spinnerIcon} alt="loading..." />
            </div>
        );
    }

    //render page loading
    const showDataLoading = () => {
        return (
            <Row>
                <img src={spinnerIcon} alt="loading..." />
            </Row>
        );
    }

    //render page error
    const showPageError = () => {
        return (
            <div>
                Oops, there was a page load error: {pageErrors}
            </div>
        );
    }

    //render data error
    const showDataError = () => {
        return (
            <Row>
                Oops, there was a data load error: {dataErrors}
            </Row>
        );
    }

    ////render loading
    //const showLoading_BAK = () => {
    //    return (
    //        <div>
    //            <img src={spinnerIcon} alt="loading..." />
    //        </div>
    //    );
    //}

    //render table container
    const showTableContainer = () => {
        return (
            <Card className="mb-4">
                <Card.Header style={{ backgroundColor: 'lightgray' }}>
                    {showInputCriteria()}
                </Card.Header>
                <Card.Body style={{ height: (screenHeight - 475).toString() + 'px', overflow: "auto" }}>
                    {(() => {
                        switch (dataStatus) {
                            case 'loading':
                                return showDataLoading()
                            case 'uploading':
                                return showBlobUploading()
                            case 'ready':
                                return showData()
                            case 'error':
                                return showDataError()
                            default:
                                return <p>No Data Loaded.</p>
                        }
                    })()}
                </Card.Body>
                <Card.Footer style={{ backgroundColor: 'lightgray' }}>
                    <Container fluid style={{ height: '40px' }} >
                    {(() => {
                        if (dataStatus == 'ready') {
                            return <Row>
                                        <Col className="d-flex justify-content-end">
                                            <PaginationComponent
                                                itemsCount={rowCount}
                                                itemsPerPage={dataCriteria.takeCount}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                pageClickHandler={handlePageClick}
                                                alwaysShown={true}
                                            />
                                        </Col>
                                    </Row>                                    
                        }
                    })()}       
                    </Container>
                </Card.Footer>
            </Card>
        );
    }

    //render input criteria
    const showInputCriteria = () => {
        return (
            <Container fluid>
                <Form>
                    <Row>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="statementId">
                                <Form.Label style={{ fontSize: '14px', fontWeight: '600', marginBottom: '3px' }}>Stmt Id</Form.Label>
                                <Form.Control name="statementIdString" size="sm" aria-label="Statement Id" disabled={dataStatus === 'loading'} onChange={handleChange} style={{ width: '75px', fontSize: '12px' }} />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group as={Col} className="mb-3" controlId="formOperator">
                                <Form.Label style={{ fontSize: '14px', fontWeight: '600', marginBottom: '3px' }}>Statement Type</Form.Label>
                                <Form.Select name="statementTypeId" size="sm" aria-label="Statement Type" disabled={dataStatus === 'loading'} onChange={handleChange} style={{ fontSize: '12px' }}>
                                    {statementTypes.map((type, i) => (
                                        <option key={type.id} value={i}>{type.type}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        {/*<Col xs="auto">*/}
                        {/*    <Form.Group as={Col} className="mb-3" controlId="formOwner">*/}
                        {/*        <Form.Label>Non-Operated Company</Form.Label>*/}
                        {/*        <Form.Select name="ownerId" size="sm" aria-label="Owner" onChange={handleChange} >*/}
                        {/*            {owners.map((owner, i) => (*/}
                        {/*                <option key={i} value={owner.id}>{owner.companyName}</option>*/}
                        {/*            ))}*/}
                        {/*        </Form.Select>*/}
                        {/*    </Form.Group>*/}
                        {/*</Col>*/}
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="operatorName">
                                <Form.Label style={{ fontSize: '14px', fontWeight: '600', marginBottom: '3px' }}>Operator Name</Form.Label>
                                <Form.Control name="operatorName" size="sm" aria-label="Operator Name" disabled={dataStatus === 'loading'} onChange={handleChange} style={{ width: '200px', fontSize: '12px' }} />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="ownerName">
                                <Form.Label style={{ fontSize: '14px', fontWeight: '600', marginBottom: '3px' }}>Owner Name</Form.Label>
                                <Form.Control name="ownerName" size="sm" aria-label="Owner Name" disabled={dataStatus === 'loading'} onChange={handleChange} style={{ width: '200px', fontSize: '12px' }} />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group as={Col} className="mb-3" controlId="statementCategoryId">
                                <Form.Label style={{ fontSize: '14px', fontWeight: '600', marginBottom: '3px' }}>Category/Organization</Form.Label>
                                <Form.Select name="statementCategoryId" size="sm" aria-label="Organization" value={dataCriteria.statementCategoryId}
                                    disabled={dataStatus === 'loading' || accountProfile.hasDocConversion} onChange={handleChange} style={{ width: '200px', fontSize: '12px' }}>
                                    {statementCategories.map((category, i) => (
                                        <option key={category.id} value={category.id}>{category.code} {(category.name === '' ? '' : '-')} {category.name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs="auto" style={{marginLeft: '-10px'}} >
                            <Form.Group as={Col} className="mb-3" controlId="buttonUpload">
                                <Form.Label as={Row}><br /></Form.Label>
                                <Button
                                    style={{ marginTop: '-11px', fontSize: '12px' }}
                                    type='button'
                                    size="sm"
                                    disabled={dataStatus === 'loading' || accountProfile.hasDocConversion}
                                    onClick={pageStatus === 'ready' ? () => setCategoryAddModalShow(true) : null}
                                >
                                    Add New Org
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '-5px'}} >
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="checkNumber">
                                <Form.Label style={{ fontSize: '14px', fontWeight: '600', marginBottom: '3px' }}>Check #</Form.Label>
                                <Form.Control name="checkNumber" size="sm" aria-label="Check #" disabled={dataStatus === 'loading'} onChange={handleChange} style={{ width: '100px', fontSize: '12px' }} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="checkDate">
                                <Form.Label style={{ fontSize: '14px', fontWeight: '600', marginBottom: '3px' }}>Check Date</Form.Label>
                                <Form.Control type="date" placeholder="Check Date" name="checkDate" size="sm" aria-label="Check Date" disabled={dataStatus === 'loading'} value={dataCriteria.checkDate} onChange={handleChange} style={{ width: '125px', fontSize: '12px' }} />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">                            
                            <Form.Group as={Col} className="mb-3" controlId="fromMonth" style={{ marginLeft: '-35px' }}>
                                <Form.Label style={{ fontSize: '14px', fontWeight: '600', marginBottom: '3px' }}>Received (From)</Form.Label>
                                <Form.Select name="fromMonth" size="sm" value={dataCriteria.fromMonth} aria-label="FromMonth" disabled={dataStatus === 'loading'} onChange={handleChange} style={{ fontSize: '12px' }} >
                                        {months.map((month, i) => (
                                            <option key={i} value={i + 1}>{month}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>                            
                        </Col>
                        <Col xs="auto" style={{marginLeft: '-15px'}} >
                            <Form.Group className="mb-3" controlId="fromYear">
                                <Form.Label style={{ fontSize: '14px', fontWeight: '600', marginBottom: '3px' }}>&nbsp;</Form.Label>
                                <Form.Select name="fromYear" size="sm" value={dataCriteria.fromYear} aria-label="FromYear" disabled={dataStatus === 'loading'} onChange={handleChange} style={{ fontSize: '12px' }}>
                                    {years.map((year, i) => (
                                        <option key={i} value={year}>{year}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="toMonth">
                                <Form.Label style={{ fontSize: '14px', fontWeight: '600', marginBottom: '3px' }}>Received (To)</Form.Label>
                                <Form.Select name="toMonth" size="sm" value={dataCriteria.toMonth} aria-label="ToMonth" disabled={dataStatus === 'loading'} onChange={handleChange} style={{ fontSize: '12px' }}>
                                    {months.map((month, i) => (
                                        <option key={i} value={i + 1}>{month}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs="auto" style={{ marginLeft: '-15px' }}>
                            <Form.Group className="mb-3" controlId="toYear">
                                <Form.Label style={{ fontSize: '14px', fontWeight: '600', marginBottom: '3px' }}>&nbsp;</Form.Label>
                                <Form.Select name="toYear" size="sm" value={dataCriteria.toYear} aria-label="ToYear" disabled={dataStatus === 'loading'} onChange={handleChange} style={{ fontSize: '12px' }}>
                                    {years.map((year, i) => (
                                        <option key={i} value={year}>{year}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="ownerNumber">
                                <Form.Label style={{ fontSize: '14px', fontWeight: '600', marginBottom: '3px' }}>Owner Id</Form.Label>
                                <Form.Control name="ownerNumber" size="sm" aria-label="Owner Id" disabled={dataStatus === 'loading'} onChange={handleChange} style={{ width: '100px', fontSize: '12px' }} />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group as={Col} className="mb-3" controlId="formStatus">
                                <Form.Label style={{ fontSize: '14px', fontWeight: '600', marginBottom: '3px' }}>Status</Form.Label>
                                <Form.Select name="statusId" size="sm" aria-label="Status" disabled={dataStatus === 'loading'} onChange={handleChange} style={{ fontSize: '12px' }}>
                                    {statusItems.map((status, i) => (
                                        <option key={status.id} value={status.id}>{status.status}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group as={Col} className="mb-3" controlId="buttonSubmit">
                                <Form.Label as={Row}><br /></Form.Label>
                                <Button
                                    style={{ marginTop: '-11px', fontSize: '12px' }}
                                    type='submit'
                                    size="sm"
                                    disabled={dataStatus === 'loading'}
                                    onClick={pageStatus === 'ready' ? submitForm : null}
                                >
                                    Search
                                </Button>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group as={Col} className="mb-3" controlId="buttonUpload">
                                <Form.Label as={Row}><br /></Form.Label>
                                <Button
                                    style={{ marginTop: '-11px', fontSize: '12px' }}
                                    type='button'
                                    size="sm"
                                    disabled={dataStatus === 'loading' || accountProfile.hasDocConversion}
                                    onClick={pageStatus === 'ready' ? () => setDocumentInputModalShow(true) : null}
                                >
                                    Upload Documents
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Container>
        );
    }

    //render datafiles
    const showData = () => {
        return (
            <Container fluid>
                <Row>
                    <table className="table table-striped" aria-labelledby="tabelLabel" style={{fontSize: '.85rem'}} >
                        <thead>
                            <tr>
                                <th><Button variant="link" style={{ textAlign: 'left', padding: '0px', fontSize: '13.5px', fontWeight: '700', color: (sortBy === 'StatementId' ? 'blue' : 'black') }} onClick={() => handleSortChange('StatementId')}>Stmt Id{sortBy === 'StatementId' ? <FontAwesomeIcon icon={ sortDirection === 'Ascending' ? faArrowUpLong : faArrowDownLong } style={{ cursor: 'pointer', padding: '0px', paddingBottom: '2px', paddingLeft: '5px', width: '8px', height: '12px' }} /> : ''}</Button></th>
                                <th><Button variant="link" style={{ textAlign: 'left', padding: '0px', fontSize: '13.5px', fontWeight: '700', color: (sortBy === 'StatementType' ? 'blue' : 'black') }} onClick={() => handleSortChange('StatementType')}>Stmt Type{sortBy === 'StatementType' ? <FontAwesomeIcon icon={sortDirection === 'Ascending' ? faArrowUpLong : faArrowDownLong} style={{ cursor: 'pointer', padding: '0px', paddingBottom: '2px', paddingLeft: '5px', width: '8px', height: '12px' }} /> : ''}</Button></th>
                                <th><Button variant="link" style={{ textAlign: 'left', padding: '0px', fontSize: '13.5px', fontWeight: '700', color: (sortBy === 'OperatorName' ? 'blue' : 'black') }} onClick={() => handleSortChange('OperatorName')}>Operator Name{sortBy === 'OperatorName' ? <FontAwesomeIcon icon={sortDirection === 'Ascending' ? faArrowUpLong : faArrowDownLong} style={{ cursor: 'pointer', padding: '0px', paddingBottom: '2px', paddingLeft: '5px', width: '8px', height: '12px' }} /> : ''}</Button></th>
                                <th><Button variant="link" style={{ textAlign: 'left', padding: '0px', fontSize: '13.5px', fontWeight: '700', color: (sortBy === 'OwnerId' ? 'blue' : 'black') }} onClick={() => handleSortChange('OwnerId')}>Owner Id{sortBy === 'OwnerId' ? <FontAwesomeIcon icon={sortDirection === 'Ascending' ? faArrowUpLong : faArrowDownLong} style={{ cursor: 'pointer', padding: '0px', paddingBottom: '2px', paddingLeft: '5px', width: '8px', height: '12px' }} /> : ''}</Button></th>
                                <th><Button variant="link" style={{ textAlign: 'left', padding: '0px', fontSize: '13.5px', fontWeight: '700', color: (sortBy === 'OwnerName' ? 'blue' : 'black') }} onClick={() => handleSortChange('OwnerName')}>Owner Name{sortBy === 'OwnerName' ? <FontAwesomeIcon icon={sortDirection === 'Ascending' ? faArrowUpLong : faArrowDownLong} style={{ cursor: 'pointer', padding: '0px', paddingBottom: '2px', paddingLeft: '5px', width: '8px', height: '12px' }} /> : ''}</Button></th>
                                <th><Button variant="link" style={{ textAlign: 'left', padding: '0px', fontSize: '13.5px', fontWeight: '700', color: (sortBy === 'StatementCategoryId' ? 'blue' : 'black') }} onClick={() => handleSortChange('StatementCategoryId')}>Category/Org{sortBy === 'StatementCategoryId' ? <FontAwesomeIcon icon={sortDirection === 'Ascending' ? faArrowUpLong : faArrowDownLong} style={{ cursor: 'pointer', padding: '0px', paddingBottom: '2px', paddingLeft: '5px', width: '8px', height: '12px' }} /> : ''}</Button></th>
                                <th><Button variant="link" style={{ textAlign: 'left', padding: '0px', fontSize: '13.5px', fontWeight: '700', color: (sortBy === 'CheckNumber' ? 'blue' : 'black') }} onClick={() => handleSortChange('CheckNumber')}>Check #{sortBy === 'CheckNumber' ? <FontAwesomeIcon icon={sortDirection === 'Ascending' ? faArrowUpLong : faArrowDownLong} style={{ cursor: 'pointer', padding: '0px', paddingBottom: '2px', paddingLeft: '5px', width: '8px', height: '12px' }} /> : ''}</Button></th>
                                <th><Button variant="link" style={{ textAlign: 'left', padding: '0px', fontSize: '13.5px', fontWeight: '700', color: (sortBy === 'CheckDate' ? 'blue' : 'black') }} onClick={() => handleSortChange('CheckDate')}>Check Date{sortBy === 'CheckDate' ? <FontAwesomeIcon icon={sortDirection === 'Ascending' ? faArrowUpLong : faArrowDownLong} style={{ cursor: 'pointer', padding: '0px', paddingBottom: '2px', paddingLeft: '5px', width: '8px', height: '12px' }} /> : ''}</Button></th>
                                <th><Button variant="link" style={{ textAlign: 'left', padding: '0px', fontSize: '13.5px', fontWeight: '700', color: (sortBy === 'CheckAmount' ? 'blue' : 'black') }} onClick={() => handleSortChange('CheckAmount')}>Check Amount{sortBy === 'CheckAmount' ? <FontAwesomeIcon icon={sortDirection === 'Ascending' ? faArrowUpLong : faArrowDownLong} style={{ cursor: 'pointer', padding: '0px', paddingBottom: '2px', paddingLeft: '5px', width: '8px', height: '12px' }} /> : ''}</Button></th>
                                <th><Button variant="link" style={{ textAlign: 'left', padding: '0px', fontSize: '13.5px', fontWeight: '700', color: (sortBy === 'ReceivedDate' ? 'blue' : 'black') }} onClick={() => handleSortChange('ReceivedDate')}>Received Date{sortBy === 'ReceivedDate' ? <FontAwesomeIcon icon={sortDirection === 'Ascending' ? faArrowUpLong : faArrowDownLong} style={{ cursor: 'pointer', padding: '0px', paddingBottom: '2px', paddingLeft: '5px', width: '8px', height: '12px' }} /> : ''}</Button></th>
                                <th><Button variant="link" style={{ textAlign: 'left', padding: '0px', fontSize: '13.5px', fontWeight: '700', color: (sortBy === 'Status' ? 'blue' : 'black') }} onClick={() => handleSortChange('Status')}>Status{sortBy === 'Status' ? <FontAwesomeIcon icon={sortDirection === 'Ascending' ? faArrowUpLong : faArrowDownLong} style={{ cursor: 'pointer', padding: '0px', paddingBottom: '2px', paddingLeft: '5px', width: '8px', height: '12px' }} /> : ''}</Button></th>
                                {/*<th>Delivery Time</th>*/}
                                {/*<th>Delivery Email</th>*/}
                                <th><Button variant="link" style={{ textAlign: 'left', padding: '0px', fontSize: '13.5px', fontWeight: '700', color: (sortBy === 'StatusDate' ? 'blue' : 'black') }} onClick={() => handleSortChange('StatusDate')}>Status Date{sortBy === 'StatusDate' ? <FontAwesomeIcon icon={sortDirection === 'Ascending' ? faArrowUpLong : faArrowDownLong} style={{ cursor: 'pointer', padding: '0px', paddingBottom: '2px', paddingLeft: '5px', width: '8px', height: '12px' }} /> : ''}</Button></th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {documents.map((document, index) => (
                                <tr key={document.id}>
                                    <td>{document.statementIdString}</td>
                                    <td>{document.statementTypeString}</td>
                                    <td>{document.operatorName}</td>
                                    <td>{document.ownerNumber}</td>
                                    <td>{document.ownerName}</td>
                                    <td>
                                        {(() => {
                                            if (categoryEditItem === document.statementIdString) {
                                                return <Form.Select name="statementCategoryId" size="sm" value={document.statementCategoryId} aria-label="Org (optional)" onChange={(e) => handleItemCategoryChange(e, document.statementId, document.sequenceNumber)} style={{width: '200px'}} >
                                                    {statementCategories.map((category, i) => (
                                                        <option key={category.id} value={category.id}>{category.code} {(category.name === '' ? '' : '-')} {category.name}</option>
                                                    ))}
                                                </Form.Select>
                                            }
                                            else if (accountProfile.hasDocConversion) {
                                                return(
                                                    <span style={{ textAlign: 'left', padding: '0px', fontSize: '13.5px', fontWeight: '700', color: 'black', fontStyle: 'normal' }}>
                                                        {(document.statementCategoryCode === '' ? '' : document.statementCategoryCode)} {(document.statementCategoryName === '' ? '' : '-')} {document.statementCategoryName}
                                                    </span>);
                                            }
                                            else {
                                                return <Button variant="link"
                                                    style={{ textAlign: 'left', padding: '0px', fontSize: '13.5px', fontWeight: '700', color: 'blue', textDecoration: (document.statementCategoryCode === '' ? 'underline' : 'underline'), fontStyle: (document.statementCategoryCode === '' ? 'italic' : 'normal') }}
                                                    onClick={() => setCategoryEditItem(document.statementIdString)}>
                                                    {(document.statementCategoryCode === '' ? 'Change' : document.statementCategoryCode)} {(document.statementCategoryName === '' ? '' : '-')} {document.statementCategoryName}
                                                    
                                                </Button>
                                            }
                                        })()}
                                    </td>
                                    <td>{document.checkNumber}</td>
                                    <td>{document.checkDateString}</td>
                                    <td>{document.checkAmountString}</td>
                                    <td>{document.receivedDateString}</td>
                                    <td>{document.statusString}</td>
                                    {/*<td>{document.deliveryTime}</td>*/}
                                    {/*<td>{document.deliveryEmail}</td>*/}
                                    <td>{document.statusDateString}</td>
                                    <td>
                                        {(() => {
                                            if (document.sequenceNumber === 0) {
                                                return <Button id={document.id} variant="primary" size="sm" style={{ width: "80px", marginLeft: "20px", fontSize: "12px" }} value={document.id}
                                                    onClick={!isDev ? null : () => handleBlobDownload(document.statementId, 0)}>
                                                    Get Source
                                                </Button>
                                            }
                                            else {
                                                return <Button id={document.id} variant="primary" size="sm" style={{ width: "80px", marginLeft: "20px", fontSize: "12px" }} value={document.id}
                                                    disabled={document.statusId !== 2 && document.statusId !== 3}
                                                    onClick={!isDev ? null : () => handleBlobDownload(document.statementId, document.sequenceNumber, index)}>
                                                    Get Data
                                                </Button>
                                            }
                                        })()}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Row>
            </Container>
        );
    }

    //render component
    return (
        <div>
            <Alert variant={alertVariant} show={alertShow} onClose={() => setAlertShow(false)} dismissible>
                {alertMessage}
            </Alert>
            <h1 hidden={alertShow} >Document Conversion</h1>
            <div hidden={alertShow}>&nbsp;</div>
            {(() => {
                switch (pageStatus) {
                    case 'loading':
                        return showPageLoading()
                    case 'ready':
                        return showTableContainer()
                    case 'error':
                        return showPageError()
                    default:
                        return <p>No Page Loaded.</p>
                }
            })()}

            <DocumentInputModal
                show={documentInputModalShow}
                onHide={() => setDocumentInputModalShow(false)}
                onNext={() => setDocumentUploadModalShow(true)}
                onAddCategory={() => setCategoryAddModalShow(true)}
                newItemCriteria={newItemCriteria}
                setNewItemCriteria={setNewItemCriteria}
                statementTypes={statementTypes.filter(t => t.type != 'All')}
                deliveryTimes={deliveryTimes.filter(t => t.time != 'All')}
                statementCategories={statementCategories}
                handleInputChange={handleInputChange}
                isEmailValid={isEmailValid}
            />

            <DocumentUploadModal
                show={documentUploadModalShow}
                onHide={() => setDocumentUploadModalShow(false)}
                onNext={() => setDocumentUploadModalShow(false)}
                newItemCriteria={newItemCriteria}
                setNewItemCriteria={setNewItemCriteria}
                statementTypes={statementTypes.filter(t => t.type != 'All')}
                deliveryTimes={deliveryTimes.filter(t => t.time != 'All')}
                handleInputChange={handleInputChange}
                handleFileUpload={handleFileUpload}
                //handlefileInputChange={handlefileInputChange}
                handlefileInputChange={handleDrop}
                handleDrop={handleDrop}
                handleBlobUpload={isDev ? handleBlobUpload : handleFileUpload}
                uploadFiles={uploadFiles}
                handleRemoveFile={handleRemoveFile}
                handleDragEnter={handleDragEnter}
                handleDragLeave={handleDragLeave}
                dragging={dragging}
                disableUploadButton={false}
            />

            <CategoryAddModal
                show={categoryAddModalShow}
                onHide={() => setCategoryAddModalShow(false)}
                newCategory={newCategory}
                setNewCategory={setNewCategory}
                statementCategories={statementCategories}
                handleCategoryInputChange={handleCategoryInputChange}
                handleAddCategory={handleAddCategory}
                isCategoryCodeValid={isCategoryCodeValid}
                isCategoryNameValid={isCategoryNameValid}
            />
            
            <CategoryChangeConfirmationModal
                show={categoryChangeConfirmationModalShow}
                onHide={() => setCategoryChangeConfirmationModalShow(false)}
                categoryChangeCriteria={categoryChangeCriteria}
                saveItemCategoryChange={saveItemCategoryChange}
            />
        </div>
    );
}

export default DocumentConversion;
