import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
//import { getConfig } from "../config";

//const { audience } = getConfig();
const baseUrl = process.env.REACT_APP_API_BASEURL;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

//const [state, setState] = useState({
//    showResult: false,
//    apiMessage: "",
//    error: null,
//});

//const { getAccessTokenSilently, loginWithPopup, getAccessTokenWithPopup } =
//    useAuth0();

//const handleConsent = async () => {
//    try {
//        await getAccessTokenWithPopup();
//        //setState({
//        //    ...state,
//        //    error: null,
//        //});
//    } catch (error) {
//        //setState({
//        //    ...state,
//        //    error: error.error,
//        //});
//    }

//    await callApi();
//};

//const handleLoginAgain = async () => {
//    try {
//        await loginWithPopup();
//        //setState({
//        //    ...state,
//        //    error: null,
//        //});
//    } catch (error) {
//        //setState({
//        //    ...state,
//        //    error: error.error,
//        //});
//    }

//    await callApi();
//};

export const apiGet = async (endpoint, getAccessTokenSilently) => {
    console.log('getting access token');

    try {
        const token = await getAccessTokenSilently({
            authorizationParams: {
                audience: audience,
                scope: "openid profile email read:messages admin impersonate docConversion",
            },
        });

        console.log('Silent Token: ' + token);
        console.log(`${baseUrl}` + endpoint);

        const response = await fetch(`${baseUrl}` + endpoint, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        //console.log('Hello Response');
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const apiPut = async (endpoint, jsonBody, getAccessTokenSilently) => {
    console.log('getting access token');

    try {
        const token = await getAccessTokenSilently({
            authorizationParams: {
                audience: audience,
                scope: "openid profile email read:messages admin impersonate",
            },
        });

        console.log('Silent Token: ' + token);
        console.log(`${baseUrl}` + endpoint);
        console.log('Json Body: ' + jsonBody);

        const response = await fetch(`${baseUrl}` + endpoint, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: jsonBody
        });

        //console.log('Hello Response: ' + response);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const apiPost = async (endpoint, body, isJson, getAccessTokenSilently) => {
    console.log('getting access token');

    try {
        const token = await getAccessTokenSilently({
            authorizationParams: {
                audience: audience,
                scope: "openid profile email read:messages admin impersonate",
            },
        });

        console.log('Silent Token: ' + token);
        console.log(`${baseUrl}` + endpoint);
        console.log('Body: ' + body);

        //set auth header
        let headerValues = {
            Authorization: `Bearer ${token}`
        };

        //body may be json or formdata; if json, include the Content-Type header
        if (isJson) {
            headerValues = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
        }

        //post the api request
        const response = await fetch(`${baseUrl}` + endpoint, {
            method: 'POST',
            headers: headerValues,
            body: body
        });

        //console.log('Hello Response: ' + response);
        return response;
    } catch (error) {
        console.log(error);
    }
};

//export const apiPostBlob = async (endpoint, body, isJson, getAccessTokenSilently) => {
//    console.log('getting access token');

//    try {
//        const token = await getAccessTokenSilently({
//            authorizationParams: {
//                audience: audience,
//                scope: "openid profile email read:messages admin impersonate",
//            },
//        });

//        console.log('Silent Token: ' + token);
//        console.log(`${baseUrl}` + endpoint);
//        console.log('Body: ' + body);

//        //set auth header
//        let headerValues = {
//            Authorization: `Bearer ${token}`
//        };

//        //body may be json or formdata; if json, include the Content-Type header
//        if (isJson) {
//            headerValues = {
//                Authorization: `Bearer ${token}`,
//                'Content-Type': 'application/json'
//            };
//        }

//        //post the api request
//        const response = await fetch(endpoint, {
//            method: 'POST',
//            headers: headerValues,
//            body: body
//        });

//        //console.log('Hello Response: ' + response);
//        return response;
//    } catch (error) {
//        console.log(error);
//    }
//};

export const apiPutBlob = async (endpoint, body) => {
    try {        
        console.log(endpoint);
        console.log('Body: ' + body);

        const response = await fetch(endpoint, {
            method: 'PUT',
            headers: {
                //'Content-Type': 'application/json'
                'x-ms-blob-type': 'BlockBlob'
            },
            //body: 'Hello There!'
            body: body
        });

        //console.log('Hello Response: ' + response);
        return response;
    } catch (error) {
        console.log(error);
    }
};

//export const apiPostFormData = async (endpoint, body, getAccessTokenSilently) => {
//    console.log('getting access token');

//    try {
//        const token = await getAccessTokenSilently({
//            authorizationParams: {
//                audience: audience,
//                scope: "openid profile email read:messages admin",
//            },
//        });

//        console.log('Silent Token: ' + token);
//        console.log(`${baseUrl}` + endpoint);
//        console.log('Body: ' + body);

//        const response = await fetch(`${baseUrl}` + endpoint, {
//            method: 'POST',
//            headers: {
//                Authorization: `Bearer ${token}`
//            },
//            body: body
//        });

//        //console.log('Hello Response: ' + response);
//        return response;
//    } catch (error) {
//        console.log(error);
//    }
//};

//below doesn't work
//try custom hook later: https://stackoverflow.com/questions/73256514/react-invalid-hook-call-creating-a-centralized-js-file
//export const apiGet = async (endpoint) => {
//    const { getAccessTokenSilently } = useAuth0();
//    try {
//        const token = await getAccessTokenSilently({
//            authorizationParams: {
//                audience: "https://quickstarts/api",
//                scope: "openid profile email read:messages admin",
//            },
//        });

//        console.log('Silent Token: ' + token);
//        console.log(`${baseUrl}` + endpoint);

//        const response = await fetch(`${baseUrl}` + endpoint, {
//            headers: {
//                Authorization: `Bearer ${token}`,
//            },
//        });

//        //console.log('Hello Response');
//        return response;
//    } catch (error) {
//        console.log(error);
//    }
//};
