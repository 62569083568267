import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChartColumn,
    faChevronUp,
    faGauge,
    faLayerGroup,
    faCloudSun,
    faHome,
    faDatabase,
    faEnvelope,
    faUpload,
    faDownload,
    faMoneyCheck,
    faBuilding,
    faArrowsLeftRight,
    faArrowsLeftRightToLine,
    faArrowsDownToLine,
    faArrowsUpToLine,
    faArrowsToCircle,
    faUserTimes,
    faArrowRightToFile,
    faRibbon
} from '@fortawesome/free-solid-svg-icons'
import React, {
  useContext, useEffect, useState,
} from 'react'
import {
  Accordion, AccordionContext, Badge, Button, Nav, useAccordionButton,
} from 'react-bootstrap'
import classNames from 'classnames'

import { LinkContainer } from 'react-router-bootstrap'

const SidebarNavItem = (props) => {
  const {
    icon,
    children,
    href,
  } = props

  return (
      <Nav.Item>
          <LinkContainer to={href}>
              <Nav.Link className="px-3 py-2 d-flex align-items-center">
                  {icon ? <FontAwesomeIcon className="nav-icon ms-n3" icon={icon} />
                      : <span className="nav-icon ms-n3" />}
                    {children}
              </Nav.Link>
          </LinkContainer>
      </Nav.Item>
  )
}

const SidebarNavTitle = (props) => {
  const { children } = props

  return (
    <li className="nav-title px-3 py-2 mt-3 text-uppercase fw-bold">{children}</li>
  )
}

const SidebarNavGroupToggle = (props) => {
  // https://react-bootstrap.github.io/components/accordion/#custom-toggle-with-expansion-awareness
  const { activeEventKey } = useContext(AccordionContext)
  const {
    eventKey, icon, children, setIsShow,
  } = props

  const decoratedOnClick = useAccordionButton(eventKey)

  const isCurrentEventKey = activeEventKey === eventKey

  useEffect(() => {
    setIsShow(activeEventKey === eventKey)
  }, [activeEventKey, eventKey, setIsShow])

  return (
    <Button
      variant="link"
      type="button"
      className={classNames('rounded-0 nav-link px-3 py-2 d-flex align-items-center flex-fill w-100 shadow-none', {
        collapsed: !isCurrentEventKey,
      })}
      onClick={decoratedOnClick}
    >
      <FontAwesomeIcon className="nav-icon ms-n3" icon={icon} />
      {children}
      <div className="nav-chevron ms-auto text-end">
        <FontAwesomeIcon size="xs" icon={faChevronUp} />
      </div>
    </Button>
  )
}

const SidebarNavGroup = (props) => {
  const {
    toggleIcon,
    toggleText,
    children,
  } = props

  const [isShow, setIsShow] = useState(false)

  return (
    <Accordion as="li" bsPrefix="nav-group" className={classNames({ show: isShow })}>
      <SidebarNavGroupToggle icon={toggleIcon} eventKey="0" setIsShow={setIsShow}>{toggleText}</SidebarNavGroupToggle>
      <Accordion.Collapse eventKey="0">
        <ul className="nav-group-items list-unstyled">
          {children}
        </ul>
      </Accordion.Collapse>
    </Accordion>
  )
}

export default function SidebarNav(props) {
    const { accountProfile } = props;
    const { isAuthenticated, getIdTokenClaims } = useAuth0();
    const roleClaimType = 'totdata/roles';
    
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        //do something

        const checkIsAdmin = async () => {
            const claims = await getIdTokenClaims();
            const roles = claims[roleClaimType]
            const verifiedIsAdmin = roles.includes('admin');
            setIsAdmin(verifiedIsAdmin);
            console.log('Has Admin Role: ' + verifiedIsAdmin);
        };

        if (isAuthenticated) {
            checkIsAdmin();
        }

    }, []);

  return (
      <ul className="list-unstyled">
          <SidebarNavItem icon={faHome} href="/">
              Home
        {/*<small className="ms-auto"><Badge bg="info" className="ms-auto">NEW</Badge></small>*/}
          </SidebarNavItem>      

          {/*{(() => {*/}
          {/*    if (currentAccount.profiles[0].profileTypeId === 1 || currentAccount.profiles[0].profileTypeId === 2) {*/}
          {/*        return <>*/}
          {/*            <SidebarNavTitle>Data</SidebarNavTitle>*/}
          {/*            */}{/*<SidebarNavItem icon={faDroplet} href="colors.html">Link 1</SidebarNavItem>*/}
          {/*            <SidebarNavItem icon={faUpload} href="/import">Import</SidebarNavItem>*/}
          {/*            <SidebarNavItem icon={faDownload} href="/export">*/}
          {/*                Export*/}
          {/*                */}{/*<small className="ms-auto"><Badge bg="danger" className="ms-auto">DEMO</Badge></small>*/}
          {/*            </SidebarNavItem>*/}
          {/*        </>*/}
          {/*    }*/}
          {/*    else {*/}
          {/*        return null*/}
          {/*    }*/}
          {/*})()}          */}

          {(() => {
              if (accountProfile.accountType === "Admin" || accountProfile.accountType === "Sender" || accountProfile.accountType === "SenderReceiver") {
                  return <>
                      <SidebarNavTitle>Operated</SidebarNavTitle>
                      <SidebarNavItem icon={faUpload} href="/import">Import</SidebarNavItem>
                      <SidebarNavItem icon={faDatabase} href="/datafiles">Check Data Files</SidebarNavItem>
                      {/*<SidebarNavItem icon={faBuilding} href="/mappedowners">Owner Mapping</SidebarNavItem>*/}
                      {/*<SidebarNavItem icon={faBuilding} href="/cdexcodes">Cdex Mapping</SidebarNavItem>*/}
                  </>
              } else if (accountProfile.hasDocConversion) {
                  return null
              }
              else {
                  return null
              }
          })()}

          {/*<SidebarNavItem icon={faEnvelope} href="/statements/1">Statements</SidebarNavItem>*/}
          {/*<SidebarNavItem icon={faArrowsLeftRight} href="/mappings/1">Mappings</SidebarNavItem>*/}

          {(() => {
              if (accountProfile.accountType === "Admin" || accountProfile.accountType === "Sender" || (accountProfile.accountType === "Receiver" && !accountProfile.hasDocConversion) || accountProfile.accountType === "SenderReceiver") {
                  return <>
                      <SidebarNavTitle>Non-Operated</SidebarNavTitle>
                      <SidebarNavItem icon={faMoneyCheck} href="/checkfiles">Check Statements</SidebarNavItem>      
                      <SidebarNavItem icon={faArrowRightToFile} href={"/documentconversion"}>Document Conversion</SidebarNavItem>
                      <SidebarNavItem icon={faBuilding} href="/sendingoperators">My Operators</SidebarNavItem>
                      <SidebarNavItem icon={faRibbon} href="/membership">Membership Plan</SidebarNavItem>
                  </>
              } else if (accountProfile.hasDocConversion) {
                  return <>
                      <SidebarNavTitle>Non-Operated</SidebarNavTitle>
                      <SidebarNavItem icon={faArrowRightToFile} href={"/documentconversion"}>Document Conversion</SidebarNavItem>
                  </>
              }
              else {
                  return null
              }
          })()}

          {(() => {
              if (isAdmin) {
                  return <>
                      <SidebarNavTitle>Admin</SidebarNavTitle>
                      <SidebarNavItem icon={faUserTimes} href="/unregister">Unregister User</SidebarNavItem>
                  </>
              }
              else {
                  return null
              }
          })()}

          {(() => {
              if (accountProfile.accountType === "Admin" || accountProfile.accountType === "Sender" || accountProfile.accountType === "SenderReceiver") {
                  return <>
                      <SidebarNavTitle>COMING SOON</SidebarNavTitle>
                      <SidebarNavItem icon={faGauge} href="/jib">JIB</SidebarNavItem>
                      <SidebarNavItem icon={faEnvelope} href="/1099">1099</SidebarNavItem>
                      <SidebarNavItem icon={faEnvelope} href="/ach">ACH</SidebarNavItem>
                      <SidebarNavItem icon={faEnvelope} href="/coa">COA</SidebarNavItem>
                      {/*<SidebarNavItem icon={faEnvelope} href="/helloworld">Hello World!</SidebarNavItem>*/}
                  </>
              } else if (accountProfile.hasDocConversion) {
                  return null
              }
              else {
                  return null
              }
          })()}

          {/*<SidebarNavTitle>Samples</SidebarNavTitle>*/}
          {/*<SidebarNavItem icon={faChartColumn} href="/dashboard">*/}
          {/*    Dashboard*/}
          {/*    <small className="ms-auto"><Badge bg="info" className="ms-auto">SAMPLE</Badge></small>*/}
          {/*</SidebarNavItem>*/}
          {/*<SidebarNavItem icon={faCloudSun} href="/weather">*/}
          {/*    Weather*/}
          {/*    <small className="ms-auto"><Badge bg="info" className="ms-auto">SAMPLE</Badge></small>*/}
          {/*</SidebarNavItem>*/}
          {/*<SidebarNavGroup toggleIcon={faLayerGroup} toggleText="SubItems">*/}
          {/*    <SidebarNavItem href="/">Home</SidebarNavItem>*/}
          {/*    <SidebarNavItem href="/dashboard">Dashboard</SidebarNavItem>*/}
          {/*    <SidebarNavItem href="/weather">Weather</SidebarNavItem>*/}
          {/*</SidebarNavGroup>*/}


        {/*<SidebarNavItem icon={faCode} href="/home">*/}
        {/*    Home*/}
        {/*    <small className="ms-auto"><Badge bg="danger" className="ms-auto">DEMO</Badge></small>*/}
        {/*  </SidebarNavItem>*/}
          
        {/*<SidebarNavItem icon={faCode} href="/book">*/}
        {/*    Book*/}
        {/*    <small className="ms-auto"><Badge bg="danger" className="ms-auto">SAMPLE</Badge></small>*/}
        {/*</SidebarNavItem>*/}
          

          {/*<SidebarNavTitle>Group</SidebarNavTitle>*/}
          {/*<SidebarNavItem icon={faPencil} href="typography.html">Import</SidebarNavItem>*/}
      {/*<SidebarNavItem icon={faDroplet} href="colors.html">Link</SidebarNavItem>*/}
      {/*<SidebarNavItem icon={faPencil} href="typography.html">Link</SidebarNavItem>*/}
      {/*<SidebarNavTitle>Group</SidebarNavTitle>*/}

      {/*<SidebarNavGroup toggleIcon={faPuzzlePiece} toggleText="Link">*/}
      {/*  <SidebarNavItem href="base/accordion.html">Sublink</SidebarNavItem>*/}
      {/*        <SidebarNavItem href="base/breadcrumb.html">Sublink</SidebarNavItem>*/}
      {/*        <SidebarNavItem href="base/cards.html">Sublink</SidebarNavItem>*/}
      {/*        <SidebarNavItem href="base/carousel.html">Sublink</SidebarNavItem>*/}
      {/*        <SidebarNavItem href="base/collapse.html">Sublink</SidebarNavItem>*/}
      {/*        <SidebarNavItem href="base/list-group.html">Sublink</SidebarNavItem>*/}
      {/*  */}{/*<SidebarNavItem href="base/navs.html">Navs</SidebarNavItem>*/}
      {/*  */}{/*<SidebarNavItem href="base/pagination.html">Pagination</SidebarNavItem>*/}
      {/*  */}{/*<SidebarNavItem href="base/popovers.html">Popovers</SidebarNavItem>*/}
      {/*  */}{/*<SidebarNavItem href="base/progress.html">Progress</SidebarNavItem>*/}
      {/*  */}{/*<SidebarNavItem href="base/scrollspy.html">Scrollspy</SidebarNavItem>*/}
      {/*  */}{/*<SidebarNavItem href="base/spinners.html">Spinners</SidebarNavItem>*/}
      {/*  */}{/*<SidebarNavItem href="base/tables.html">Tables</SidebarNavItem>*/}
      {/*  */}{/*<SidebarNavItem href="base/tabs.html">Tabs</SidebarNavItem>*/}
      {/*  */}{/*<SidebarNavItem href="base/tooltips.html">Tooltips</SidebarNavItem>*/}
      {/*</SidebarNavGroup>*/}

      {/*<SidebarNavGroup toggleIcon={faCode} toggleText="Link">*/}
      {/*  <SidebarNavItem href="buttons/buttons.html">Buttons</SidebarNavItem>*/}
      {/*  <SidebarNavItem href="buttons/button-group.html">Buttons Group</SidebarNavItem>*/}
      {/*  <SidebarNavItem href="buttons/dropdowns.html">Dropdowns</SidebarNavItem>*/}
      {/*</SidebarNavGroup>*/}

      {/*<SidebarNavItem icon={faChartPie} href="charts.html">Link</SidebarNavItem>*/}

      {/*<SidebarNavGroup toggleIcon={faFileLines} toggleText="Link">*/}
      {/*        <SidebarNavItem href="forms/form-control.html">Sublink</SidebarNavItem>*/}
      {/*        <SidebarNavItem href="forms/select.html">Sublink</SidebarNavItem>*/}
      {/*        <SidebarNavItem href="forms/checks-radios.html">Sublink</SidebarNavItem>*/}
      {/*        <SidebarNavItem href="forms/range.html">Sublink</SidebarNavItem>*/}
      {/*        <SidebarNavItem href="forms/input-group.html">Sublink</SidebarNavItem>*/}
      {/*        <SidebarNavItem href="forms/floating-labels.html">Sublink</SidebarNavItem>*/}
      {/*        <SidebarNavItem href="forms/layout.html">Sublink</SidebarNavItem>*/}
      {/*        <SidebarNavItem href="forms/validation.html">Sublink</SidebarNavItem>*/}
      {/*</SidebarNavGroup>*/}

      {/*<SidebarNavGroup toggleIcon={faStar} toggleText="Link">*/}
      {/*        <SidebarNavItem href="icons/coreui-icons-free.html">Sublink</SidebarNavItem>*/}
      {/*        <SidebarNavItem href="icons/coreui-icons-brand.html">Sublink</SidebarNavItem>*/}
      {/*        <SidebarNavItem href="icons/coreui-icons-flag.html">Sublink</SidebarNavItem>*/}
      {/*</SidebarNavGroup>*/}

      {/*<SidebarNavGroup toggleIcon={faBell} toggleText="Link">*/}
      {/*        <SidebarNavItem href="notifications/alerts.html">Sublink</SidebarNavItem>*/}
      {/*        <SidebarNavItem href="notifications/badge.html">Sublink</SidebarNavItem>*/}
      {/*        <SidebarNavItem href="notifications/modals.html">Sublink</SidebarNavItem>*/}
      {/*        <SidebarNavItem href="notifications/toasts.html">Sublink</SidebarNavItem>*/}
      {/*</SidebarNavGroup>*/}

      {/*<SidebarNavItem icon={faCalculator} href="widgets.html">*/}
      {/*  Link*/}
      {/*  <small className="ms-auto"><Badge bg="info">NEW</Badge></small>*/}
      {/*</SidebarNavItem>*/}

      {/*<SidebarNavTitle>Extras</SidebarNavTitle>*/}

      {/*<SidebarNavGroup toggleIcon={faStar} toggleText="Pages">*/}
      {/*  <SidebarNavItem icon={faRightToBracket} href="login">Login</SidebarNavItem>*/}
      {/*  <SidebarNavItem icon={faAddressCard} href="register">Register</SidebarNavItem>*/}
      {/*  <SidebarNavItem icon={faBug} href="404.html">Error 404</SidebarNavItem>*/}
      {/*  <SidebarNavItem icon={faBug} href="500.html">Error 500</SidebarNavItem>*/}
      {/*</SidebarNavGroup>*/}

      {/*<SidebarNavItem icon={faFileLines} href="docs.html">Link</SidebarNavItem>*/}
      {/*<SidebarNavItem icon={faLayerGroup} href="https://totdata.com">Link</SidebarNavItem>*/}
    </ul>
  )
}
